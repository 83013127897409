import { Col, Input, Row } from "antd";
import React from "react";
const { Search } = Input;

const SearchInput = (props) => {
  const { handleChange, onSearch } = props;
  return (
    <Row className="search-filter-container mar-bottom-20">
      <Col lg={8}>
        <div className="searchGrp">
          <Search
            placeholder="Search "
            size="large"
            onSearch={onSearch}
            onChange={handleChange}
          />
        </div>
      </Col>
    </Row>
  );
};

export default SearchInput;
