import React from "react";
import SinglePackageTable from "./SinglePackageTable";

const UsedSingleCar = ({ setArgs, args, packageUsedList }) => {

  return (
    <>
      <div className="multipleCarPost shadow-paper auto-height ">
        <SinglePackageTable setArgs={setArgs} args={args} packageUsedList={packageUsedList} />
      </div>
    </>
  );
};

export default UsedSingleCar;
