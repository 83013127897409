import { Button, Form } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Card from "../../../../components/common/Card";
import { POST_STATUS } from "../../../../constants";
import {
  cylinderOption,
  doorOption,
  fuelOption,
  seatOption,
  steeringOption,
  transmissionOption,
  WARRANTY_OPTIONS,
} from "../../../../constants/DummyData";
import { ROUTES } from "../../../../constants/routesEnd";
import {
  editCarPostStatusAction,
  getCarDetailAction
} from "../../../../Redux/CarSlice";
import {
  getBodyConditionAction,
  getBodyTypeAction,
  getCarColorAction,
  getCarMakeAction,
  getCityAction,
  getHorsePowerAction,
  getInteriorColorAction,
  getMechenicalConditionAction,
} from "../../../../Redux/MetaSlice";
import {
  RenderInput,
  RenderSelect,
  RenderTextArea,
} from "../../../../utils/formField";

const Multicardetails = ({ item }) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const { postId } = useParams();
  const { stepperInputs } = useSelector((state) => state?.metaSlice);
  const { carDetail } = useSelector((state) => state?.car);

  useEffect(() => {
    if (postId) {
      dispatch(getCarDetailAction(`/${postId}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dropDownCommon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      city: carDetail?.city || "-",
      make: carDetail?.make || "-",
      model: carDetail?.model || "-",
      year: carDetail?.registrationYear || "-",
      fuel: carDetail?.fuelType || "-",
      bodyType: carDetail?.bodyType || "-",
      steeringSide: carDetail?.steeringSide || "-",
      noOfSeats: carDetail?.numberOfSeats || "-",
      price: carDetail?.price || "-",
      title: carDetail?.title || "-",
      description: carDetail?.description || "-",
      location: carDetail?.location || "-",
      color: carDetail?.carColor || "-",
      interiorColor: carDetail?.interiorColor || "-",
      kmDriven: carDetail?.kmDriven || "-",
      door: carDetail?.doors || "-",
      noOfCylinder: carDetail?.numberOfCylinders || "-",
      transmissionType: carDetail?.transmissionType || "-",
      horsePower: carDetail?.horsePower || "-",
      bodyCondition: carDetail?.bodyCondition || "-",
      warranty: carDetail?.warranty || "-",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carDetail]);

  const dropDownCommon = () => {
    dispatch(getCityAction());
    dispatch(getBodyConditionAction());
    dispatch(getBodyTypeAction());
    dispatch(getMechenicalConditionAction());
    dispatch(getCarColorAction());
    dispatch(getCarMakeAction());
    dispatch(getInteriorColorAction());
    dispatch(getHorsePowerAction());
  };

  const handleStatusChange = (e) => {
    dispatch(editCarPostStatusAction(`?postId=${carDetail?.id}&status=${e}`))
      .then(res => toast.success('Car Status Updated Successfully '))
  };

  return (
    <>
      <>
        <div className="postDetails">
          <Card>
            <Form
              layout="vertical"
              form={form}
              disabled={true}
            >
              <div className="row">
                <div className="postHeader col-md-12 d-flex align-items-center justify-content-between">
                  <h3 className="postTitle">Car Details</h3>
                  <div className="detailsBtnGrp">
                    <Button htmlType="button" disabled={false}>
                      <Link to={ROUTES.usedCar}>Cancel</Link>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <RenderSelect
                    name="city"
                    options={stepperInputs?.city}
                    colWidth={24}
                    formLabel="City"
                  />
                </div>
                <div className="col-md-6">
                  <RenderSelect
                    name="make"
                    options={stepperInputs?.Make}
                    colWidth={24}
                    formLabel="Make"
                  />
                </div>
                <div className="col-md-6">
                  <RenderInput
                    colClassName="form-group"
                    colWidth={24}
                    name="model"
                    formLabel="Model"
                    placeholder="Model"
                  />
                </div>
                <div className="col-md-6">
                  <RenderInput
                    colClassName="form-group"
                    colWidth={24}
                    name="year"
                    placeholder="Year"
                    label="year"
                    formLabel="Year"
                  />
                </div>
                <div className="col-md-6">
                  <RenderSelect
                    name="fuel"
                    options={fuelOption}
                    colWidth={24}
                    formLabel="Fuel Type"
                  />
                </div>
                <div className="col-md-6">
                  <RenderSelect
                    name="bodyType"
                    options={stepperInputs?.BodyType}
                    colWidth={24}
                    formLabel="Body Type"
                  />
                </div>
                <div className="col-md-6">
                  <RenderSelect
                    name="steeringSide"
                    options={steeringOption}
                    colWidth={24}
                    formLabel="Steering Side"
                  />
                </div>
                <div className="col-md-6">
                  <RenderSelect
                    name="noOfSeats"
                    options={seatOption}
                    colWidth={24}
                    formLabel="Number of Seats"
                  />
                </div>
                <div className="col-md-6">
                  <RenderInput
                    colClassName="form-group"
                    colWidth={24}
                    name="price"
                    placeholder="price"
                    label="Price"
                    formLabel="Price"
                  />
                </div>
                <div className="col-md-6">
                  <RenderInput
                    colClassName="form-group"
                    colWidth={24}
                    name="title"
                    placeholder="title"
                    label="Title"
                    formLabel="Title"
                  />
                </div>
                <div className="col-md-12">
                  <RenderTextArea
                    name="description"
                    formLabel="Description"
                    rows={2}
                    readOnly={false}
                    disabled={true}
                  />
                </div>
                <div className="col-md-6">
                  <RenderInput
                    colClassName="form-group"
                    colWidth={24}
                    name="location"
                    placeholder="location"
                    label="Location"
                    formLabel="Location"
                  />
                </div>
                <div className="col-md-6">
                  <RenderSelect
                    name="color"
                    options={stepperInputs?.CarColor}
                    colWidth={24}
                    formLabel="Color"
                  />
                </div>
                <div className="col-md-6">
                  <RenderSelect
                    name="interiorColor"
                    options={stepperInputs?.InteriorColor}
                    colWidth={24}
                    formLabel="Interior Color"
                  />
                </div>
                <div className="col-md-6">
                  <RenderInput
                    colClassName="form-group"
                    colWidth={24}
                    name="kmDriven"
                    placeholder="Km Driven"
                    label="Km Driven"
                    formLabel="Km Driven"
                  />
                </div>
                <div className="col-md-6">
                  <RenderSelect
                    name="door"
                    options={doorOption}
                    colWidth={24}
                    formLabel="Doors"
                  />
                </div>
                <div className="col-md-6">
                  <RenderSelect
                    name="noOfCylinder"
                    options={cylinderOption}
                    colWidth={24}
                    formLabel="Number of Cylinder"
                  />
                </div>
                <div className="col-md-6">
                  <RenderSelect
                    name="transmissionType"
                    options={transmissionOption}
                    colWidth={24}
                    formLabel="Transmission Type"
                  />
                </div>
                <div className="col-md-6">
                  <RenderSelect
                    name="horsePower"
                    options={stepperInputs?.HorsePower}
                    colWidth={24}
                    formLabel="Horse Power"
                  />
                </div>
                <div className="col-md-6">
                  <RenderSelect
                    name="bodyCondition"
                    options={stepperInputs?.BodyCondition}
                    colWidth={24}
                    formLabel="Body Condition"
                  />
                </div>
                <div className="col-md-6">
                  <RenderSelect
                    name="warranty"
                    options={WARRANTY_OPTIONS}
                    colWidth={24}
                    formLabel="Warranty"
                  />
                </div>
                <div className="col-md-6">
                  <RenderSelect
                    name="status"
                    options={POST_STATUS}
                    onChange={(e) => handleStatusChange(e)}
                    defaultValue={1}
                    colWidth={24}
                    formLabel="Status"
                    colClassName="carDetailsDropdown"
                    disabled={false}
                  />
                </div>
                <div className="col-md-12">
                  <label style={{ color: "white" }}>Image</label>
                  <div className="d-flex flex-wrap detailImage">
                    {carDetail?.attachments &&
                      carDetail?.attachments?.map((item, index) => (
                        <figure className="mar-top-10 mar-right-16" key={index}>
                          <img src={item} alt="car" />
                        </figure>
                      ))}
                  </div>
                </div>
                <div className="col-xl-12 d-flex justify-content-end mar-top-8">
                  <Button disabled={false}>
                    <Link to={ROUTES.usedCar}>Cancel</Link>
                  </Button>
                </div>
              </div>
            </Form>
          </Card>
        </div>
      </>
    </>
  );
};

export default Multicardetails;
