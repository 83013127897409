import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Dropdown, Form, Menu, Table } from "antd";

import { ROUTES } from "../../../constants/routesEnd";
import CustomModal from "../../../components/common/Modal";
import { RenderInput } from "../../../utils/formField";
import UploadFile from "../../../components/common/Upload";
import { DotIcon } from "../../../svg";
import { editCategoryAction, uploadImageAction } from "../../../Redux/CategorySlice";

const CategoryTable = ({ args, setArgs, categoryList }) => {

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accessoryId, setAccessoryId] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [fileupload, setFileupload] = useState(null);
  const [imgError, setImgError] = useState(false);
  const [attachmentId, setAttachmentId] = useState(0);
  const [removedAttachmentsId, setRemovedAttachmentsId] = useState(0);
  const categoryData = useSelector(state => state?.category?.categoryList);

  useEffect(() => {
    if (fileupload) {
      const formData = new FormData();
      formData.append('attachments', fileupload);
      dispatch(uploadImageAction(formData))
        .then(res => {
          setImgError(false)
          setAttachmentId(res.result[0])
          setRemovedAttachmentsId(imageUrl?.id)
          setImageUrl(res.result[0]);
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileupload])


  // Edit Category
  const handleEditCategory = (item) => {
    setIsModalOpen(true)
    setImageUrl(item?.attchment)
    setAccessoryId(item?.accessoryId)
    setAttachmentId(item?.attchment)
    form.setFieldsValue({
      name: item?.name
    })
  }

  const handleCancel = () => {
    setIsModalOpen(false);
    setImageUrl(null);
    setFileupload(null);
    setImgError(false);
    form.resetFields();
  };

  const onFinish = (value) => {


    if (fileupload || imageUrl) {

      const data = {
        accessoryId: accessoryId,
        name: value?.name,
        attachmentId: attachmentId?.id,
        removedAttachmentsId: removedAttachmentsId
      }
      setIsModalOpen(false)
      form.resetFields();
      setImageUrl(null);
      setFileupload(null);
      setImgError(false);
      setAttachmentId(null);
      setRemovedAttachmentsId(0);
      dispatch(editCategoryAction(data))
        .then(res => {
          categoryList()
          toast.success('Category Updated Successfully')
        })
        .catch(err => {
          toast.error(err?.ResponseException)
        })
    }
    else {
      setImgError(true);
    }
  };

  const option = (item) => {
    return (
      <Menu
        items={[
          {
            key: "1",
            label: (
              <Link to={ROUTES.viewCategory + `/${item?.accessoryId}`} >
                View
              </Link>
            ),
          },
          {
            key: "2",
            label: (
              <Link to="" onClick={() => handleEditCategory(item)} >
                Edit
              </Link>
            ),
          },
        ]}
      />
    );
  };

  const columns = [
    {
      title: "Category Id",
      dataIndex: "accessoryId",
      key: "accessoryId",
      render: (row) => (
        <div>
          <div>{row}</div>
        </div>
      ),
    },

    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
      render: (row) => (
        <div>
          <div>{row ? row : "-"}</div>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 100,
      render: (row, item) => (
        <div className="option">
          <Dropdown
            overlay={() => option(item)}
            trigger={["hover"]}
            placement="bottomLeft"
          >
            <Button type="text" icon={<DotIcon />}> </Button>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={categoryData?.data || []}
        rowKey={(row) => row?.accessoryId}
        scroll={{ x: 980 }}
        pagination={
          {
            pageSize: args?.pageSize,
            showSizeChanger: false,
            total: categoryData?.totalRecords || 10,
          }
        }
        onChange={(page) => {
          setArgs({ ...args, pageNumber: parseInt(page.current) });
        }}
      ></Table>

      <CustomModal title="Edit Category" open={isModalOpen} footer={false} onCancel={handleCancel}>
        <div className="postDetails">
          <Form layout="vertical"
            onFinish={onFinish}
            form={form}
          >
            <div>
              <RenderInput
                colClassName="form-group"
                colWidth={24}
                name="name"
                placeholder="Name"
                formLabel="Name"
                rules={[
                  {
                    required: true,
                    message: "This field is mandatory",
                  },
                ]}
              />
            </div>

            <div className="col-md-6">

              <UploadFile uploadText="Upload Image" setFileupload={setFileupload} imageUrl={imageUrl?.url} uplaodWidth={130} uplaodHeight={130} fileupload={fileupload} setImageUrl={setImageUrl} />
              {imgError && <span style={{ color: 'red' }}>This field is mandatory</span>}
            </div>

            <div className="col-xl-12 d-flex justify-content-end mar-top-8">
              <Button
                type="primary"
                htmlType="submit"
                className="mar-right-8"
              >
                Save
              </Button>
              <Button>
                <Link to="" onClick={handleCancel}>Cancel</Link>
              </Button>
            </div>
          </Form>
        </div>
      </CustomModal>

    </>
  );
};

export default CategoryTable;
