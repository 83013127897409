import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Menu, Table } from "antd";

import CustomModal from "../../../../components/common/Modal";
import { ROUTES } from "../../../../constants/routesEnd";
import { removePackageAction } from "../../../../Redux/PackageSlice";
import { toast } from "react-toastify";
import { DotIcon } from "../../../../svg";
import { Packagecomancolumns } from "../../../../constants/TableCommonColumn/packagemanagementColums";

const NewMutipleCarTable = ({ args, setArgs, packageNewList }) => {
  const dispatch = useDispatch();
  const newMultipleData = useSelector((state) => state?.package?.packageList);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isId, setIsId] = useState("");

  const showModal = (item) => {
    setIsId(item?.packageId);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(removePackageAction(`/${isId}`))
      .then((res) => {
        toast.success("Package Deleted Successfully");
        packageNewList();
      })
      .catch((err) => toast.error(err?.ResponseException));
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const option = (item) => {
    return (
      <Menu
        items={[
          {
            key: "1",
            label: (
              <Link to={ROUTES.viewPackage + `/${item?.packageId}`}>View</Link>
            ),
          },
          {
            key: "2",
            label: (
              <Link to="" onClick={() => showModal(item)}>
                Delete
              </Link>
            ),
          },
          {
            key: "2",
            label: (
              <Link to={ROUTES.editPackage + `/${item?.packageId}`}>Edit</Link>
            ),
          },
        ]}
      />
    );
  };

  const columns = [
    ...Packagecomancolumns,
    //Actions
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 100,
      render: (row, item) => (
        <div className="option">
          <Dropdown
            overlay={() => option(item)}
            trigger={["hover"]}
            placement="bottomLeft"
          >
            <Button type="text" icon={<DotIcon />}>
              {" "}
            </Button>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={newMultipleData?.data || []}
        scroll={{ x: 980 }}
        rowKey={(row) => row?.packageId}
        pagination={{
          pageSize: args?.pageSize,
          showSizeChanger: false,
          total: newMultipleData?.totalRecords
            ? newMultipleData?.totalRecords
            : 1,
        }}
        onChange={(page) => {
          setArgs({ ...args, pageNumber: parseInt(page.current) });
        }}
      />

      <CustomModal
        title="Delete Package"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to delete?</p>
      </CustomModal>
    </>
  );
};

export default NewMutipleCarTable;
