export const Packagecomancolumns = [
  {
    title: "Package Id",
    dataIndex: "packageId",
    key: "postId",
    render: (row) => (
        <div>{row}</div>
    ),
  },
  {
    title: "Package Name",
    dataIndex: "packageName",
    key: "packageName",
    render: (row) => (
        <div>{row ? row : "-"}</div>
    ),
  },
  {
    title: "Package Price",
    dataIndex: "price",
    key: "price",
    render: (row) => (
        <div>{row ? row : "-"}</div>
    ),
  },
  {
    title: "No of Days",
    dataIndex: "noOfDays",
    key: "noOfDays",
    render: (row) => {
      return (
          <div>{row ? row : "-"}</div>
      );
    },
  },
  {
    title: "Feature Days",
    dataIndex: "featuredDays",
    key: "featuredDays",
    render: (row) => (
        <div>{row ? row : "-"}</div>
    ),
  },

];