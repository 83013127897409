import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import BreadCrumbs from "../../../../../components/common/Breadcrumbs";
import { getCarDetailListAction } from "../../../../../Redux/CarSlice";
import { LS_NEW_CAR_TAB } from "../../../../../constants";
import PostTable from "./PostList";
import { ROUTES } from "../../../../../constants/routesEnd";

const list = [
  {
    name: "New Car",
    link: ROUTES.newCar,
    isActive: true,
  },
  {
    name: "Multiple Car Details",
    link: ROUTES.newCarMultipleCarDetails,
    isActive: false,
  },
];

const NewMultiplePost = () => {

  const dispatch = useDispatch();
  const { postId } = useParams();

  useEffect(() => {
    localStorage.setItem(LS_NEW_CAR_TAB, "2");
  }, []);

  useEffect(() => {
    dispatch(getCarDetailListAction(`/${postId}`))
  }, [postId, dispatch]);

  const carNewList = () => {
    dispatch(getCarDetailListAction(`/${postId}`));
  };

  return (
    <>
      <div className="postDetails">
        <BreadCrumbs list={list} />
        <PostTable autoPartList={carNewList} />
      </div>
    </>
  );
};

export default NewMultiplePost;
