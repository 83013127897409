import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const PublicRoute = ({ children }) => {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(state => state?.auth?.isLoggedIn);

    useEffect(() => {
        if(isLoggedIn) {navigate('/dashboard',{ replace: true })}
    }, [isLoggedIn, navigate])

    return (
        <>
            {children}
        </>
    )
}

export default PublicRoute;