import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Dropdown, Menu, Table } from "antd";


import { ROUTES } from "../../../../constants/routesEnd";
import { DotIcon } from "../../../../svg";
import "../../CarManagement.scss";
import { comonColumns } from "../../../../constants/TableCommonColumn/tabelColums";

const UsedMutipleCarTable = ({ args, setArgs }) => {

  const usedMultipleData = useSelector((state) => state?.car?.carList);

  const option = (item) => {
    return (
      <Menu
        items={[
          {
            key: "1",
            label: (
              <Link to={`${ROUTES.usedCarMultipleCarDetails}/${item?.postId}`}>View</Link>
            ),
          }
        ]}
      />
    );
  };
  const columns = [
    ...comonColumns,
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 100,
      render: (row, item) => (
        <div className="option">
          <Dropdown
            overlay={() => option(item)}
            trigger={["hover"]}
            placement="bottomLeft"
          >
            <Button type="text" icon={<DotIcon />}> </Button>
          </Dropdown>
        </div>
      ),
    },
  ];


  return (
    <>
      <Table
        columns={columns}
        dataSource={usedMultipleData?.data || []}
        rowKey={(row) => row?.postId}
        scroll={{ x: 1240 }}
        pagination={{
          pageSize: args?.pageSize,
          showSizeChanger: false,
          total: usedMultipleData?.totalRecords
            ? usedMultipleData?.totalRecords
            : 1,
        }}
        onChange={(page, filters, sorter) => {
          setArgs({ ...args, pageNumber: parseInt(page.current), OrderBy: sorter.order, SortField: sorter.field });
        }}
      />

    </>
  );
};

export default UsedMutipleCarTable;
