import React, { useEffect, useState } from "react";
import { Button, Form } from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import CategoryTable from "./CategoryTable";
import { ROUTES } from "../../../constants/routesEnd";
import { RenderInput } from "../../../utils/formField";
import UploadFile from "../../../components/common/Upload";
import CustomModal from "../../../components/common/Modal";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { addCategory, getCategoryList, removeImageAction, uploadImageAction } from "../../../Redux/CategorySlice";
import { toast } from "react-toastify";
import '../CategoryManagement.scss'
import { loaderChange } from "../../../Redux/AuthSlice";

const list = [
  {
    name: "Category Management",
    link: ROUTES.CategoryManagement,
    isActive: true,
  },
  {
    name: "category ",
    link: "/",
    isActive: false,
  },
];

const CategoryManagement = () => {

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [fileupload, setFileupload] = useState(null);
  const [imgError, setImgError] = useState(false);
  const [attachmentId, setAttachmentId] = useState(null);
  const [args, setArgs] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  useEffect(() => {
    dispatch(loaderChange(true))
    const getData = setTimeout(() => {
      categoryList()
      dispatch(loaderChange(false))
    }, 500)

    return () => clearTimeout(getData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args]);

  useEffect(() => {
    if (fileupload) {
      const formData = new FormData();
      formData.append('attachments', fileupload);
      dispatch(uploadImageAction(formData))
        .then(res => {
          setImgError(false)
          setAttachmentId(res.result[0])
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileupload])

  const categoryList = () => {
    dispatch(
      getCategoryList(
        `PageNumber=${args.pageNumber}&PageSize=${args.pageSize}`
      )
    )
  };

  const handleAddCategory = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    form.resetFields();
    setImageUrl(null);
    setFileupload(null);
    setIsModalOpen(false);
    setImgError(false);
    dispatch(removeImageAction(`/${attachmentId?.id}`))
  };

  const onFinish = (value) => {


    if (fileupload) {
      const data = {
        name: value?.name,
        attachmentId: attachmentId?.id
      }
      form.resetFields();
      setImageUrl(null);
      setFileupload(null);
      setIsModalOpen(false);
      setImgError(false);
      dispatch(addCategory(data))
        .then(res => {
          categoryList()
          toast.success('Category Added Successfully')
        })
        .catch(err => toast.error(err?.ResponseException))
    }
    else {
      setImgError(true);
    }
  };

  return (
    <>
      <div className="categoryMain">
        <div className="categoryTitle">
          <BreadCrumbs list={list} />
          <Button onClick={handleAddCategory}>ADD category</Button>
        </div>
        <div className="shadow-paper auto-height">
          <CategoryTable args={args} setArgs={setArgs} categoryList={categoryList} />

        </div>
      </div>

      <CustomModal title="Add Category" open={isModalOpen} footer={false} onCancel={handleCancel}>
        <div className="postDetails">
          <Form layout="vertical"
            onFinish={onFinish}
            form={form}>
            <div>
              <RenderInput
                colClassName="form-group"
                colWidth={24}
                name="name"
                placeholder="Name"
                formLabel="Name"
                rules={[
                  {
                    required: true,
                    message: "This field is mandatory",
                  },
                ]}
              />
            </div>

            <div className="col-md-6">

              <UploadFile uploadText="Upload Image" setFileupload={setFileupload} imageUrl={imageUrl} fileupload={fileupload} setImageUrl={setImageUrl} />
              {imgError && <span style={{ color: 'red' }}>This field is mandatory</span>}
            </div>

            <div className="col-xl-12 d-flex justify-content-end mar-top-8">
              <Button
                type="primary"
                htmlType="submit"
                className="mar-right-8"
              >
                Save
              </Button>
              <Button>
                <Link to="" onClick={handleCancel}>Cancel</Link>
              </Button>
            </div>
          </Form>
        </div>
      </CustomModal>
    </>
  );
};

export default CategoryManagement;
