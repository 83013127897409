
// API ENDPOINTS
export const API_LOGIN = `api/admin/login`;

export const API_FORGOT_PASSWORD = `admin/auth/forgotPassword`;

export const API_RESET_PASSWORD = `admin/auth/resetPassword`;

export const API_CHANGE_PASSWORD = `api/admin/password/change`;

// User managament 

export const API_USER_LIST = `api/admin/users/getall`;
export const API_USER_DELETE = `api/admin/users`;
export const API_USER_EDIT = `api/admin/users`;
export const API_USER_INFO = `api/admin/users`;

// car management

export const API_NEWCAR_LIST = `api/admin/posts/getall`;
export const API_CAR_LIST_DELETE = `api/admin/posts/detail`;
export const API_CAR_DETAIL_LIST = `api/admin/posts`;
export const API_CAR_DETAIL = `api/admin/posts/detail`;
export const API_EDIT_CAR_DETAIL = `api/admin/posts/detail`;
export const API_EDIT_CAR_POST_STATUS = `api/admin/posts`;

// Post Dropdown
export const API_GET_BODY_CONDITION = `api/bodycondition`;
export const API_GET_BODY_TYPE = `api/bodytype`;
export const API_GET_CAR_COLOR = `api/car-color`;
export const API_GET_CAR_MODEL = `api/carmodel`;
export const API_GET_CITY = `api/city`;
export const API_GET_HORSE_POWER = `api/horsepower`;
export const API_GET_INTERIOR_COLOR = `api/interiorcolor`;
export const API_GET_MAKE = `api/car-make`;
export const API_GET_MECHENICAL_CONDITION = `api/mechenical-condition`;
export const API_GET_USAGE = `api/usage`;

//Package Management

export const API_PACKAGEMANAGE_LIST = `api/admin/packages/getall`;
export const API_ADD_PACKAGEMANAGE = `api/admin/packages`;
export const API_EDIT_PACKAGEMANAGE = `api/admin/packages`;
export const API_GET_PACKAGEMANAGE_DEATILES = `api/admin/packages`;
export const API_REMOVE_PACKAGEMANAGE = `api/admin/packages`;


//Category Management

//Category Management
export const API_CATEGORY_LIST = `api/admin/accessory/type/getall`;
export const API_ADD_CATEGORY = `api/admin/accessory/type`;
export const API_CATEGORY_VIEW = `api/admin/accessory/type`
export const API_EDIT_CATEGORY = `api/admin/accessory/type`;

//subCategory Management

export const API_SUB_CATEGORY_LIST = `api/admin/accessory/subtype/getall`;
export const API_ADD_SUB_CATEGORY = `api/admin/accessory/subtype`;
export const API_EDIT_SUB_CATEGORY = `api/admin/accessory/subtype`;

//post transaction

export const API_POST_TRASACTION_LIST = `api/admin/posts/transaction/getall`;

//vat Management
export const API_VAT_LIST = `api/admin/packages/vat`;

//Upload Image

export const API_UPLOAD_IMAGE = `api/attachment/upload`;
export const API_REMOVE_IMAGE = `api/attachment`;

// contact 
export const API_CONTACT = `api/admin/contactus`

