import { createSlice } from "@reduxjs/toolkit";
import { API_VAT_LIST } from "../constants/api";
import { VAT_LIST_F, VAT_LIST_S } from "../constants/type";

const initialState = {
    vatInfo: {},
};

export const getVatPercentageAction = () => ({
    type: "API",
    payload: {
        url: API_VAT_LIST,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: VAT_LIST_S,
            payload: data,
        }),
        error: (data) => ({
            type: VAT_LIST_F,
            payload: {},
        }),
    },
});

export const editVatPercentageAction = (data) => ({
    type: "API",
    payload: {
        url: API_VAT_LIST,
        method: "PUT",
        hideLoader: false,
        data: data
    },
});


// Reducer
const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(VAT_LIST_S, (state, action) => {
            state.vatInfo = action.payload;
        });
        builder.addCase(VAT_LIST_F, (state, action) => {
            state.vatInfo = {};
        });

    },
});

export default userSlice.reducer;
