import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_CHANGE_PASSWORD, API_FORGOT_PASSWORD, API_LOGIN, API_RESET_PASSWORD } from "../constants/api";
import { CHANGE_PASSWORD_F, CHANGE_PASSWORD_S, FORGOT_PASSWORD_F, FORGOT_PASSWORD_S, LOGIN_F, LOGIN_S, RESET_PASSWORD_F, RESET_PASSWORD_S } from "../constants/type";
import { LS_AUTHTOKEN, LS_NEW_CAR_PACKAGE_TAB, LS_NEW_CAR_TAB, LS_USED_CAR_PACKAGE_TAB, LS_USED_CAR_TAB, LS_USER } from "../constants"

const initialState = {
  // Global loader for api 
  isLoading: false,

  // Auth Data
  isLoggedIn: false,
  userData: {},
};

export const loginAction = (data) => ({
  type: "API",
  payload: {
    url: API_LOGIN,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: LOGIN_S,
      payload: data,
    }),
    error: (data) => ({
      type: LOGIN_F,
      payload: {},
    }),
  },
});

export const forgotPasswordAction = (data) => ({
  type: "API",
  payload: {
    url: API_FORGOT_PASSWORD,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: FORGOT_PASSWORD_S,
      payload: data,
    }),
    error: (data) => ({
      type: FORGOT_PASSWORD_F,
      payload: {},
    }),
  },
});
export const resetPasswordAction = (data) => ({
  type: "API",
  payload: {
    url: API_RESET_PASSWORD,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: RESET_PASSWORD_S,
      payload: data,
    }),
    error: (data) => ({
      type: RESET_PASSWORD_F,
      payload: {},
    }),
  },
});
export const changePasswordAction = (data) => ({
  type: "API",
  payload: {
    url: API_CHANGE_PASSWORD,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: CHANGE_PASSWORD_S,
      payload: data,
    }),
    error: (data) => ({
      type: CHANGE_PASSWORD_F,
      payload: {},
    }),
  },
});

// Reducer
const loginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    loaderChange: (state, payload) => {
      state.isLoading = payload.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LOGIN_S, (state, action) => {
      // Default header for auth
      axios.defaults.headers.common["Authorization"] = `Bearer ${action.payload?.token}`;
      localStorage.setItem(LS_AUTHTOKEN, JSON.stringify(action.payload?.token));
      localStorage.setItem(LS_USER, JSON.stringify(action.payload));
      localStorage.setItem(LS_NEW_CAR_PACKAGE_TAB, "1");
      localStorage.setItem(LS_USED_CAR_PACKAGE_TAB, "1");
      localStorage.setItem(LS_NEW_CAR_TAB, "1");
      localStorage.setItem(LS_USED_CAR_TAB, "1");
      state.userData = action.payload;
      state.isLoggedIn = true;
    });
    builder.addCase(LOGIN_F, (state, action) => {
      // remove items on logout
      delete axios.defaults.headers.common['Authorization']
      localStorage.removeItem(LS_AUTHTOKEN);
      localStorage.removeItem(LS_USER);
      localStorage.removeItem(LS_NEW_CAR_PACKAGE_TAB);
      localStorage.removeItem(LS_USED_CAR_PACKAGE_TAB);
      localStorage.removeItem(LS_NEW_CAR_TAB);
      localStorage.removeItem(LS_USED_CAR_TAB);
      state.userData = {};
      state.isLoggedIn = false;
    });
  },
});

export const { loaderChange } = loginSlice.actions;
export default loginSlice.reducer;
