import React from "react";
import UsedMutiplePackageTable from "./UsedMutipleCarTable";


const UsedMutipleCar = ({ args, setArgs, packageUsedList }) => {

  return (
    <>
      <div className="multipleCarPost shadow-paper auto-height ">
        <UsedMutiplePackageTable args={args} setArgs={setArgs} packageUsedList={packageUsedList} />
      </div>
    </>
  );
};

export default UsedMutipleCar;
