import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Tabs } from "antd";

import NewSingleCar from "./singleCar";
import NewMutipleCar from "./multipleCar";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { LS_NEW_CAR_TAB, PRODUCTS, PRODUCTS_TYPE } from "../../../constants";
import { getCarList } from "../../../Redux/CarSlice";
import { ROUTES } from "../../../constants/routesEnd";
import { loaderChange } from "../../../Redux/AuthSlice";

const NewCar = () => {

  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState("1");
  const [args, setArgs] = useState({
    pageNumber: 1,
    pageSize: 10,
    searchString: '',
    OrderBy: '',
    SortField: ''
  });

  const getCurrentTab = localStorage.getItem(LS_NEW_CAR_TAB.toString())

  useEffect(() => {
    return () => {
      localStorage.setItem(LS_NEW_CAR_TAB, "1")
    }
  }, [])

  useEffect(() => {

    dispatch(loaderChange(true))
    const getData = setTimeout(() => {
      carNewList()
      dispatch(loaderChange(false))
    }, 500)

    return () => clearTimeout(getData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args, currentTab])

  useEffect(() => {
    setCurrentTab(getCurrentTab);
  }, [getCurrentTab])

  const carNewList = () => {
    if (currentTab === "1" && getCurrentTab === "1") {
      const data = {
        postType: PRODUCTS.new.value,
        carPostSubType: PRODUCTS_TYPE.single.value,
        searchString: args.searchString
      };
      dispatch(
        getCarList(
          data,
          `?PageNumber=${args.pageNumber}&PageSize=${args.pageSize}&OrderBy=${args.OrderBy}&SortField=${args.SortField}`
        )
      )
    }
    else {
      const data = {
        postType: PRODUCTS.new.value,
        carPostSubType: PRODUCTS_TYPE.multiple.value,
        searchString: args.searchString
      };
      dispatch(
        getCarList(
          data,
          `?PageNumber=${args.pageNumber}&PageSize=${args.pageSize}&OrderBy=${args.OrderBy}&SortField=${args.SortField}`
        )
      )
    }
  };

  const list = [
    {
      name: "Post Management",
      link: ROUTES.newCar,
      isActive: true,
    },
    {
      name: "New Car",
      link: "/",
      isActive: false,
    },
  ];

  const tabNames = [
    {
      label: `Single car post`,
      key: "1",
      children: <NewSingleCar args={args} setArgs={setArgs} carNewList={carNewList} />,
    },
    {
      label: `Multiple car post`,
      key: "2",
      children: <NewMutipleCar args={args} setArgs={setArgs} carNewList={carNewList} />,
    },
  ];

  const onChange = (key) => {
    localStorage.setItem(LS_NEW_CAR_TAB, key);
    setCurrentTab(key);
  };

  return (
    <>
      <BreadCrumbs list={list} />
      <Tabs
        onChange={onChange}
        size="middle"
        defaultActiveKey={currentTab}
        activeKey={getCurrentTab}
        items={tabNames}
      />
    </>
  );
};

export default NewCar;
