import { createSlice } from "@reduxjs/toolkit";
import { CAR_DETAILS_LIST_F, CAR_DETAILS_LIST_S, CAR_DETAIL_F, CAR_DETAIL_S, CAR_LIST_F, CAR_LIST_S, } from "../constants/type";
import { API_CAR_DETAIL, API_CAR_DETAIL_LIST, API_CAR_LIST_DELETE, API_EDIT_CAR_DETAIL, API_EDIT_CAR_POST_STATUS, API_NEWCAR_LIST } from "../constants/api";

const initialState = {
  carList: [],
  carDetailList: {},
  carDetail: {},
};

export const getCarList = (data, args) => ({
  type: "API",
  payload: {
    url: API_NEWCAR_LIST + args,
    method: "POST",
    hideLoader: false,
    data: data,
    success: (data) => ({
      type: CAR_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: CAR_LIST_F,
      payload: [],
    }),
  },
});

export const getCarDetailListAction = (args) => ({
  type: "API",
  payload: {
    url: API_CAR_DETAIL_LIST + args,
    method: "GET",
    hideLoader: false,
    success: (data) => ({
      type: CAR_DETAILS_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: CAR_DETAILS_LIST_F,
      payload: [],
    }),
  },
});

export const deleteCarListAction = (data) => ({
  type: "API",
  payload: {
    url: API_CAR_LIST_DELETE + data,
    method: "DELETE",
    hideLoader: false,
  },
});

export const getCarDetailAction = (args) => ({
  type: "API",
  payload: {
    url: API_CAR_DETAIL + args,
    method: "GET",
    hideLoader: false,
    success: (data) => ({
      type: CAR_DETAIL_S,
      payload: data,
    }),
    error: (data) => ({
      type: CAR_DETAIL_F,
      payload: {},
    }),
  },
});

export const editCarDetailAction = (data) => ({
  type: "API",
  payload: {
    url: API_EDIT_CAR_DETAIL,
    method: "PUT",
    hideLoader: false,
    data: data
  },
});

export const editCarPostStatusAction = (args) => ({
  type: "API",
  payload: {
    url: API_EDIT_CAR_POST_STATUS + args,
    method: "PUT",
    hideLoader: false,
  },
});

// reducer
const carSlice = createSlice({
  name: "car",
  initialState: initialState,

  extraReducers: (builder) => {

    builder.addCase(CAR_LIST_S, (state, action) => {
      state.carList = action.payload;
    });
    builder.addCase(CAR_LIST_F, (state, action) => {
      state.carList = [];
    });

    builder.addCase(CAR_DETAILS_LIST_S, (state, action) => {
      state.carDetailList = action.payload;
    });
    builder.addCase(CAR_DETAILS_LIST_F, (state, action) => {
      state.carDetailList = {};
    });

    builder.addCase(CAR_DETAIL_S, (state, action) => {
      state.carDetail = action.payload;
    });
    builder.addCase(CAR_DETAIL_F, (state, action) => {
      state.carDetail = {};
    });
  },
});

export default carSlice.reducer;