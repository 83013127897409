import React, { Suspense } from 'react'
import axios from 'axios'
import { Provider } from "react-redux"
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import ScrollToTop from '../components/common/scroll'
import Loader from '../components/common/loader'
import { setupAxios } from '../utils'
import { store } from '../Redux/store'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from './ErrorBoundary'

const { PUBLIC_URL } = process.env

setupAxios(axios, store);

const AppContainer = () => (
    <Provider store={store}>
        <Suspense fallback={<Loader isSuspense />}>
            <Loader>
            <ErrorBoundary >
                <BrowserRouter basename={PUBLIC_URL}>
                    <ScrollToTop>
                        <Routes />
                    </ScrollToTop>
                </BrowserRouter>
            </ErrorBoundary>
            </Loader>
        </Suspense>
    <ToastContainer/>
    </Provider>
)

export default AppContainer;