import moment from "moment";

export const comonColumns = [
  {
    title: "Post Id",
    dataIndex: "postId",
    key: "postId",
    render: (row) => (
        <div>{row}</div>
    ),
  },
  {
    title: "Seller Name",
    dataIndex: "sellerName",
    key: "sellerName",
    width: '40%',
    sorter: true,
    render: (row) => (
        <div>{row ? row : "-"}</div>
    ),
  },
  {
    title: "Phone Number",
    dataIndex: "sellerPhoneNumber",
    key: "sellerPhoneNumber",
    render: (row) => (
        <div>{row ? row : "-"}</div>
    ),
  },
  {
    title: "Number of Cars",
    dataIndex: "numberOfCar",
    key: "numberOfCar",
    render: (row) => (
        <div>{row ? row : "-"}</div>
    ),
  },
  {
    title: "Package Name",
    dataIndex: "packageName",
    key: "packageName",
    render: (row) => (
        <div>{row ? row : "-"}</div>
    ),
  },
  {
    title: "Package Price",
    dataIndex: "packagePrice",
    key: "packagePrice",
    sorter: true,
    render: (row) => (
        <div>{row ? row : "-"}</div>
    ),
  },
  {
    title: "Transaction Id",
    dataIndex: "transactionId",
    key: "transactionId",
    render: (row) => (
        <div>{row ? row : "-"}</div>
    ),
  },
  {
    title: "Create Date",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: true,
    render: (row) => {
      return (
          <div>{row ? moment(row).format("L") : "-"}</div>
      );
    },
  },
  {
    title: "Update Date",
    dataIndex: "updatedAt",
    key: "updatedAt",
    sorter: true,
    render: (row) => (
        <div>{row ? moment(row).format("L") : "-"}</div>
    ),
  },
];