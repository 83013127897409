import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Dropdown, Menu, Table } from "antd";
import { toast } from "react-toastify";

import CustomModal from "../../../components/common/Modal";
import { ROUTES } from "../../../constants/routesEnd";
import { removePackageAction } from "../../../Redux/PackageSlice";
import { DotIcon } from "../../../svg";
import { Packagecomancolumns } from "../../../constants/TableCommonColumn/packagemanagementColums";

const AutoPartsTable = ({ args, setArgs, packageautoPartList }) => {

  const dispatch = useDispatch();

  const autoPartData = useSelector(state => state?.package?.packageList);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isId, setIsId] = useState("");

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = (item) => {
    setIsId(item?.packageId);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(removePackageAction(`/${isId}`))
      .then(res => {
        toast.success('Package Deleted Successfully');
        packageautoPartList();
      })
      .catch((err) => toast.error(err?.ResponseException));
    setIsModalOpen(false);
  };

  const option = (item) => {
    return (
      <Menu
        items={[
          {
            key: "1",
            label: (
              <Link to={ROUTES.viewPackage + `/${item?.packageId}`} >
                View
              </Link>
            ),
          },
          {
            key: "2",
            label: (
              <Link to="" onClick={() => showModal(item)}>
                Delete
              </Link>
            ),
          },
          {
            key: "2",
            label: (
              <Link to={ROUTES.editPackage + `/${item?.packageId}`} >
                Edit
              </Link>
            ),
          },
        ]}
      />
    );
  };

  const columns = [
    ...Packagecomancolumns,
    //Actions
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 100,
      render: (row, item) => (
        <div className="option">
          <Dropdown
            overlay={() => option(item)}
            trigger={["hover"]}
            placement="bottomLeft"
          >
            <Button type="text" icon={<DotIcon />}> </Button>
          </Dropdown>
        </div>
      ),
    },

  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={autoPartData?.data || []}
        rowKey={(row) => row?.packageId}
        scroll={{ x: 980 }}
        pagination={
          {
            pageSize: args?.pageSize,
            showSizeChanger: false,
            total: autoPartData?.totalRecords || 10,
          }
        }
        onChange={(page) => {
          setArgs({ ...args, pageNumber: parseInt(page.current) });
        }}
      ></Table>

      <CustomModal title='Delete Package' open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Are you sure you want to delete?</p>
      </CustomModal>
    </>
  );
};

export default AutoPartsTable;
