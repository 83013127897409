import { createSlice } from "@reduxjs/toolkit";
import { API_GET_BODY_CONDITION, API_GET_BODY_TYPE, API_GET_CAR_COLOR, API_GET_CAR_MODEL, API_GET_CITY, API_GET_HORSE_POWER, API_GET_INTERIOR_COLOR, API_GET_MAKE, API_GET_MECHENICAL_CONDITION, API_GET_USAGE } from "../constants/api";
import { BODY_CONDITION_F, BODY_CONDITION_S, BODY_TYPE_F, BODY_TYPE_S, CAR_COLOR_F, CAR_COLOR_S, CAR_MODEL_F, CAR_MODEL_S, CITY_F, CITY_S, HORSE_POWER_F, HORSE_POWER_S, INTERIOR_COLOR_F, INTERIOR_COLOR_S, MAKE_F, MAKE_S, MECHENICAL_CONDITION_F, MECHENICAL_CONDITION_S, USAGE_F, USAGE_S } from "../constants/type";

export const getBodyConditionAction = () => ({
    type: "API",
    payload: {
        url: API_GET_BODY_CONDITION,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: BODY_CONDITION_S,
            payload: data,
        }),
        error: (data) => ({
            type: BODY_CONDITION_F,
            payload: [],
        }),
    },
});

export const getBodyTypeAction = () => ({
    type: "API",
    payload: {
        url: API_GET_BODY_TYPE,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: BODY_TYPE_S,
            payload: data,
        }),
        error: (data) => ({
            type: BODY_TYPE_F,
            payload: [],
        }),
    },
});

export const getCityAction = () => ({
    type: "API",
    payload: {
        url: API_GET_CITY,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: CITY_S,
            payload: data,
        }),
        error: (data) => ({
            type: CITY_F,
            payload: [],
        }),
    },
});

export const getCarMakeAction = () => ({
    type: "API",
    payload: {
        url: API_GET_MAKE,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: MAKE_S,
            payload: data,
        }),
        error: (data) => ({
            type: MAKE_F,
            payload: [],
        }),
    },
});

export const getCarColorAction = () => ({
    type: "API",
    payload: {
        url: API_GET_CAR_COLOR,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: CAR_COLOR_S,
            payload: data,
        }),
        error: (data) => ({
            type: CAR_COLOR_F,
            payload: [],
        }),
    },
});

export const getCarModelAction = () => ({
    type: "API",
    payload: {
        url: API_GET_CAR_MODEL,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: CAR_MODEL_S,
            payload: data,
        }),
        error: (data) => ({
            type: CAR_MODEL_F,
            payload: [],
        }),
    },
});

export const getHorsePowerAction = () => ({
    type: "API",
    payload: {
        url: API_GET_HORSE_POWER,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: HORSE_POWER_S,
            payload: data,
        }),
        error: (data) => ({
            type: HORSE_POWER_F,
            payload: [],
        }),
    },
});

export const getInteriorColorAction = () => ({
    type: "API",
    payload: {
        url: API_GET_INTERIOR_COLOR,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: INTERIOR_COLOR_S,
            payload: data,
        }),
        error: (data) => ({
            type: INTERIOR_COLOR_F,
            payload: [],
        }),
    },
});

export const getMechenicalConditionAction = () => ({
    type: "API",
    payload: {
        url: API_GET_MECHENICAL_CONDITION,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: MECHENICAL_CONDITION_S,
            payload: data,
        }),
        error: (data) => ({
            type: MECHENICAL_CONDITION_F,
            payload: [],
        }),
    },
});

export const getUsageAction = () => ({
    type: "API",
    payload: {
        url: API_GET_USAGE,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: USAGE_S,
            payload: data,
        }),
        error: (data) => ({
            type: USAGE_F,
            payload: [],
        }),
    },
});

const initialState = {
    stepperInputs: {
        city: [{ label: 'No data', value: '' }],
        BodyCondition: [{ label: 'No data', value: '' }],
        BodyType: [{ label: 'No data', value: '' }],
        CarColor: [{ label: 'No data', value: '' }],
        CarModel: [{ label: 'No data', value: '' }],
        HorsePower: [{ label: 'No data', value: '' }],
        InteriorColor: [{ label: 'No data', value: '' }],
        Make: [{ label: 'No data', value: '' }],
        MechenicalCondition: [{ label: 'No data', value: '' }],
        Usage: [{ label: 'No data', value: '' }],
        Category: [{ label: 'No data', value: '' }],
    },
};
// Reducer
const metaSlice = createSlice({
    name: "post",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(BODY_CONDITION_S, (state, action) => {
            state.stepperInputs.BodyCondition = action.payload?.map((item) => {
                return ({ label: item?.name, value: item?.id })
            });
        });

        builder.addCase(BODY_TYPE_S, (state, action) => {
            state.stepperInputs.BodyType = action.payload?.map((item) => {
                return ({ label: item?.name, value: item?.id })
            });
        });

        builder.addCase(CAR_COLOR_S, (state, action) => {
            state.stepperInputs.CarColor = action.payload?.map((item) => {
                return ({ label: item?.name, value: item?.id })
            });
        });

        builder.addCase(CAR_MODEL_S, (state, action) => {
            state.stepperInputs.CarModel = action.payload?.map((item) => {
                return ({ label: item?.name, value: item?.id })
            });
        });

        builder.addCase(CITY_S, (state, action) => {
            state.stepperInputs.city = action.payload?.map((item) => {
                return ({ label: item?.name, value: item?.id })
            });
        });

        builder.addCase(HORSE_POWER_S, (state, action) => {
            state.stepperInputs.HorsePower = action.payload?.map((item) => {
                return ({ label: item?.name, value: item?.id })
            });
        });

        builder.addCase(INTERIOR_COLOR_S, (state, action) => {
            state.stepperInputs.InteriorColor = action.payload?.map((item) => {
                return ({ label: item?.name, value: item?.id })
            });
        });

        builder.addCase(MAKE_S, (state, action) => {
            state.stepperInputs.Make = action.payload?.map((item) => {
                return ({ label: item?.name, value: item?.id })
            });
        });

        builder.addCase(MECHENICAL_CONDITION_S, (state, action) => {
            state.stepperInputs.MechenicalCondition = action.payload?.map((item) => {
                return ({ label: item?.name, value: item?.id })
            });
        });

        builder.addCase(USAGE_S, (state, action) => {
            state.stepperInputs.Usage = action.payload?.map((item) => {
                return ({ label: item?.name, value: item?.id })
            });
        });

    },
});

export default metaSlice.reducer;
