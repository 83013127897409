import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Dropdown, Menu, Table } from "antd";
import { ROUTES } from "../../../../constants/routesEnd";
import { DotIcon } from "../../../../svg";
import { comonColumns } from "../../../../constants/TableCommonColumn/tabelColums";
import "../../CarManagement.scss";

const NewMutipleCarTable = ({ args, setArgs }) => {

  const newMultipleData = useSelector((state) => state?.car?.carList);

  const option = (item) => {
    return (
      <Menu
        items={[
          {
            key: "1",
            label: (
              <Link to={`${ROUTES.newCarMultipleCarDetails}/${item?.postId}`}>
                View
              </Link>
            ),
          },
        ]}
      />
    );
  };

  const columns = [
    ...comonColumns,
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 100,
      render: (row, item) => (
        <div className="option">
          <Dropdown
            overlay={() => option(item)}
            trigger={["hover"]}
            placement="bottomLeft"
          >
            <Button type="text" icon={<DotIcon />}>
              {" "}
            </Button>
          </Dropdown>
        </div>
      ),
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={newMultipleData?.data || []}
        scroll={{ x: 1240 }}
        rowKey={(row) => row?.postId}
        pagination={{
          pageSize: args?.pageSize,
          showSizeChanger: false,
          total: newMultipleData?.totalRecords
            ? newMultipleData?.totalRecords
            : 10,
        }}
        onChange={(page, filters, sorter) => {
          setArgs({
            ...args,
            pageNumber: parseInt(page.current),
            OrderBy: sorter.order,
            SortField: sorter.field,
          });
        }}
      />
    </>
  );
};

export default NewMutipleCarTable;
