import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "antd";
import { toast } from "react-toastify";

import BreadCrumbs from "../../../../../components/common/Breadcrumbs";
import Card from "../../../../../components/common/Card";
import {
  RenderInput,
  RenderSelect,
  RenderTextArea,
} from "../../../../../utils/formField";
import { ROUTES } from "../../../../../constants/routesEnd";
import {
  editCarPostStatusAction,
  getCarDetailListAction,
} from "../../../../../Redux/CarSlice";
import { LS_NEW_CAR_TAB, POST_STATUS } from "../../../../../constants";

const list = [
  {
    name: "New Car",
    link: ROUTES.newCar,
    isActive: true,
  },
  {
    name: "Single Car Details",
    link: "/",
    isActive: false,
  },
];

const NewSinglePost = () => {
  const dispatch = useDispatch();
  const { postId } = useParams();
  const [form] = Form.useForm();

  const { postDetails } = useSelector((state) => state?.car?.carDetailList);

  useEffect(() => {
    localStorage.setItem(LS_NEW_CAR_TAB, "1");
    dispatch(getCarDetailListAction(`/${postId}`));
  }, [postId]);

  useEffect(() => {
    form.setFieldsValue({
      city: postDetails?.data[0]?.city || "-",
      make: postDetails?.data[0]?.make || "-",
      model: postDetails?.data[0]?.model || "-",
      year: postDetails?.data[0]?.registrationYear || "-",
      fuel: postDetails?.data[0]?.fuelType || "-",
      bodyType: postDetails?.data[0]?.bodyType || "-",
      steeringSide: postDetails?.data[0]?.steeringSide || "-",
      noOfSeats: postDetails?.data[0]?.numberOfSeats || "-",
      price: postDetails?.data[0]?.price || "-",
      title: postDetails?.data[0]?.title || "-",
      description: postDetails?.data[0]?.description || "-",
      location: postDetails?.data[0]?.location || "-",
      color: postDetails?.data[0]?.carColor || "-",
      interiorColor: postDetails?.data[0]?.interiorColor || "-",
      milage: postDetails?.data[0]?.milage || "-",
      door: postDetails?.data[0]?.doors || "-",
      noOfCylinder: postDetails?.data[0]?.numberOfCylinders || "-",
      transmissionType: postDetails?.data[0]?.transmissionType || "-",
      horsePower: postDetails?.data[0]?.horsePower || "-",
      status: postDetails?.data[0]?.status || 1,
      sold: "sold"
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId, postDetails]);

  const handleStatusChange = (e) => {
    dispatch(editCarPostStatusAction(`?postId=${postDetails?.data[0]?.postDetailsId}&status=${e}`))
      .then(res => toast.success('Car Status Updated Successfully '))
  };

  return (
    <>
      <div className="postDetails">
        <BreadCrumbs list={list} />
        <Card>
          <Form
            layout="vertical"
            form={form}
            disabled={true}
          >
            <div className="row">
              <div className="postHeader col-md-12 d-flex align-items-center justify-content-between">
                <h3 className="postTitle">Car Details</h3>
                <div className="detailsBtnGrp">

                  <Button htmlType="button" disabled={false}>
                    <Link to={ROUTES.newCar}>Cancel</Link>
                  </Button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <RenderSelect
                  name="city"
                  colWidth={24}
                  formLabel="City"
                  colClassName="carDetailsDropdown"
                />
              </div>
              <div className="col-md-6">
                <RenderSelect
                  name="make"
                  colWidth={24}
                  formLabel="Make"
                  colClassName="carDetailsDropdown"
                />
              </div>
              <div className="col-md-6">
                <RenderInput
                  colClassName="form-group"
                  colWidth={24}
                  name="model"
                  formLabel="Model"
                  placeholder="Model"
                />
              </div>
              <div className="col-md-6">
                <RenderInput
                  colClassName="form-group"
                  colWidth={24}
                  name="year"
                  placeholder="Year"
                  label="year"
                  formLabel="Year"
                />
              </div>
              <div className="col-md-6">
                <RenderSelect
                  name="fuel"
                  colWidth={24}
                  formLabel="Fuel Type"
                  colClassName="carDetailsDropdown"
                />
              </div>
              <div className="col-md-6">
                <RenderSelect
                  name="bodyType"
                  colWidth={24}
                  formLabel="Body Type"
                  colClassName="carDetailsDropdown"
                />
              </div>
              <div className="col-md-6">
                <RenderSelect
                  name="steeringSide"
                  colWidth={24}
                  formLabel="Steering Side"
                  colClassName="carDetailsDropdown"
                />
              </div>
              <div className="col-md-6">
                <RenderSelect
                  name="noOfSeats"
                  colWidth={24}
                  formLabel="Number of Seats"
                  colClassName="carDetailsDropdown"
                />
              </div>
              <div className="col-md-6">
                <RenderInput
                  colClassName="form-group"
                  colWidth={24}
                  name="price"
                  placeholder="price"
                  label="Price"
                  formLabel="Price"
                />
              </div>
              <div className="col-md-6">
                <RenderInput
                  colClassName="form-group"
                  colWidth={24}
                  name="title"
                  placeholder="title"
                  label="Title"
                  formLabel="Title"
                />
              </div>
              <div className="col-md-12">
                <RenderTextArea
                  name="description"
                  formLabel="Description"
                  rows={2}
                  disabled={true}
                />
              </div>
              <div className="col-md-6">
                <RenderInput
                  colClassName="form-group"
                  colWidth={24}
                  name="location"
                  placeholder="location"
                  label="Location"
                  formLabel="Location"
                />
              </div>
              <div className="col-md-6">
                <RenderSelect
                  name="color"
                  colWidth={24}
                  formLabel="Color"
                  colClassName="carDetailsDropdown"
                />
              </div>
              <div className="col-md-6">
                <RenderSelect
                  name="interiorColor"
                  colWidth={24}
                  formLabel="Interior Color"
                  colClassName="carDetailsDropdown"
                />
              </div>
              <div className="col-md-6">
                <RenderInput
                  colClassName="form-group"
                  colWidth={24}
                  name="milage"
                  placeholder="Mileage"
                  label="Mileage"
                  formLabel="Mileage"
                />
              </div>
              <div className="col-md-6">
                <RenderSelect
                  name="door"
                  colWidth={24}
                  formLabel="Doors"
                  colClassName="carDetailsDropdown"
                />
              </div>
              <div className="col-md-6">
                <RenderSelect
                  name="noOfCylinder"
                  colWidth={24}
                  formLabel="Number of Cylinder"
                  colClassName="carDetailsDropdown"
                />
              </div>
              <div className="col-md-6">
                <RenderSelect
                  name="transmissionType"
                  colWidth={24}
                  formLabel="Transmission Type"
                  colClassName="carDetailsDropdown"
                />
              </div>
              <div className="col-md-6">
                <RenderSelect
                  name="horsePower"
                  colWidth={24}
                  formLabel="Horse Power"
                  colClassName="carDetailsDropdown"
                />
              </div>
              {postDetails?.data[0]?.status !== 3
                &&
                <div className="col-md-6">
                  <RenderSelect
                    name="status"
                    options={POST_STATUS}
                    onChange={(e) => handleStatusChange(e)}
                    defaultValue={1}
                    colWidth={24}
                    formLabel="Status"
                    colClassName="carDetailsDropdown"
                    disabled={false}
                  />
                </div>
              }
              {postDetails?.data[0]?.status === 3
                && <div className="col-md-6">
                  <RenderInput
                    colClassName="form-group"
                    colWidth={24}
                    name="sold"
                    // placeholder="Sold"
                    formLabel="Status"
                    defaultValue="Sold"
                  />
                </div>
              }
              <div className="col-md-12">
                <label style={{ color: "white" }}>Image</label>
                <div className="d-flex flex-wrap detailImage">
                  {postDetails?.data[0]?.attachments &&
                    postDetails?.data[0]?.attachments?.map((item) => (
                      <figure className="mar-top-10 mar-right-16" key={item}>
                        <img src={item} alt="car" />
                      </figure>
                    ))}
                </div>
              </div>
              <div className="col-xl-12 d-flex justify-content-end mar-top-8">
                <Button disabled={false}>
                  <Link to={ROUTES.newCar}>Cancel</Link>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default NewSinglePost;
