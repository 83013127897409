import React from "react";
import { Col, Form, Input, Select } from "antd";
import Autocomplete from "react-google-autocomplete";

import TextArea from "antd/lib/input/TextArea";
import { GOOGLE_PLACE_KEY } from "../constants";

export const RenderInput = (props) => {
  return (
    <>
      <Col
        xl={props.colWidth.xl ? props.colWidth.xl : props.colWidth}
        xs={props.colWidth.xs}
        className={props.colClassName}
        offset={props.coloffset}
      >
        <Form.Item
          name={props.name}
          labelCol={24}
          label={props.formLabel}
          rules={props.rules}
        >
          <Input placeholder={props.placeholder} suffix={props.suffix} disabled={props.disabled} />
        </Form.Item>
      </Col>
    </>
  );
};

export const RenderPassword = (props) => {
  return (
    <>
      <Col
        xl={props.colWidth}
        className={props.colClassName}
        offset={props.coloffset}
      >
        <Form.Item
          name={props.name}
          labelCol={24}
          label={props.formLabel}
          rules={props.rules}
        >
          <Input.Password
            placeholder={props.placeholder}
            visibilityToggle={props.visibilityToggle}
            size="large"
          />
        </Form.Item>
      </Col>
    </>
  );
};

export const RenderTextArea = (props) => {
  return (
    <Form.Item className="form-group" name={props.name} label={props.formLabel} rules={props.rules}>
      <TextArea placeholder={props.placeholder} title="demo" rows={props.rows} readOnly={props.readOnly} disabled={props.disabled} />
    </Form.Item>
  );
};

export const RenderSelect = (props) => {
  return (
    <Col
      xl={props.colWidth.xl ? props.colWidth.xl : props.colWidth}
      xs={props.colWidth.xs}
      className={props.colClassName}
      offset={props.coloffset}
    >
      <Form.Item
        name={props.name}
        labelCol={24}
        label={props.formLabel}
        className="form-group"
        rules={props.rules}
      >
        <Select
          // showSearch
          defaultValue={props.defaultValue}
          onChange={props.onChange}
          options={props.options}
          disabled={props.disabled}
        />
      </Form.Item>
    </Col>
  );
};

export const RenderLocationInput = (props) => {
  return (
    <>
      <Col
        xl={props.colWidth.xl ? props.colWidth.xl : props.colWidth}
        xs={props.colWidth.xs}
        className={props.colClassName}
        offset={props.coloffset}
      >
        <Form.Item
          name={props.name}
          labelCol={24}
          label={props.formLabel}
          rules={props.rules}
        >
          {/* <Input placeholder={props.placeholder} suffix={props.suffix} disabled={props.disabled} /> */}
          <Autocomplete
            placeholder={props.placeholder}
            apiKey={GOOGLE_PLACE_KEY}
            options={{
              types: [],
              componentRestrictions: { country: "ae" },
              fields: ['ALL']
            }}
            onPlaceSelected={props.onPlaceSelected}
            defaultValue={props.defaultValue}
          />
        </Form.Item>
      </Col>
    </>
  );
};