import React from "react";
import NewMutipleCarTable from "./NewMultipleCar";

const NewMutipleCar = ({ args, setArgs, packageNewList }) => {


  return (
    <>
      <div className="multipleCarPost shadow-paper auto-height ">
        <NewMutipleCarTable args={args} setArgs={setArgs} packageNewList={packageNewList} />
      </div>
    </>
  );
};

export default NewMutipleCar;
