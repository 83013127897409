import { createSlice } from "@reduxjs/toolkit";
import { API_CONTACT } from "../constants/api";
import { CONTACT_LIST_F, CONTACT_LIST_S } from "../constants/type";

const initialState = {
    contactList: [],
};

export const getContactList = (args) => ({
    type: "API",
    payload: {
        url: API_CONTACT + args,
        method: "GET",
        hideLoader: false,
        // data: data,
        success: (data) => ({
            type: CONTACT_LIST_S,
            payload: data,
        }),
        error: (data) => ({
            type: CONTACT_LIST_F,
            payload: [],
        }),
    },
});


// reducer
const contactSlice = createSlice({
    name: "contact",
    initialState: initialState,
    extraReducers: (builder) => {

        builder.addCase(CONTACT_LIST_S, (state, action) => {
            state.contactList = action.payload;
        });
        builder.addCase(CONTACT_LIST_F, (state, action) => {
            state.contactList = [];
        });

    },
});


export default contactSlice.reducer;