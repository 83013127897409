import React from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown, Menu, Table } from "antd";
import { useSelector } from "react-redux";

import { ROUTES } from "../../../../../constants/routesEnd";
import { DotIcon } from "../../../../../svg";
import { multiplecarlistcolumns } from "../../../../../constants/TableCommonColumn/multiplecarListColums";

const PostTable = ({ args, setArgs }) => {
  const autoPartData = useSelector(
    (state) => state?.car?.carDetailList?.postDetails
  );

  const option = (item) => {
    return (
      <Menu
        items={[
          {
            key: "1",
            label: (
              <Link
                to={`${ROUTES.newCarMultipleCarListDetails}/${item?.postDetailsId}`}
              >
                View
              </Link>
            ),
          },
        ]}
      />
    );
  };

  const columns = [
    ...multiplecarlistcolumns,
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 100,
      render: (row, item) => (
        <div className="option">
          <Dropdown
            overlay={() => option(item)}
            trigger={["hover"]}
            placement="bottomLeft"
          >
            <Button type="text" icon={<DotIcon />}>
              {" "}
            </Button>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={autoPartData?.data || []}
        rowKey={(row) => row?.postDetailsId}
        scroll={{ x: 980 }}
        pagination={{
          pageSize: args?.pageSize,
          showSizeChanger: false,
          total: autoPartData?.totalRecords || 10,
        }}
        onChange={(page, filters, sorter) => {
          setArgs({
            ...args,
            pageNumber: parseInt(page.current),
            OrderBy: sorter.order,
            SortField: sorter.field,
          });
        }}
      ></Table>
    </>
  );
};

export default PostTable;
