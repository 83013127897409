import { createSlice } from "@reduxjs/toolkit";
import { PACKAGE_DETAILE_F, PACKAGE_DETAILE_S, PACKAGE_LIST_F, PACKAGE_LIST_S, } from "../constants/type";
import { API_ADD_PACKAGEMANAGE, API_EDIT_PACKAGEMANAGE, API_GET_PACKAGEMANAGE_DEATILES, API_PACKAGEMANAGE_LIST, API_REMOVE_PACKAGEMANAGE } from "../constants/api";

const initialState = {
  packageList: [],
  packageDetaile: {},
};

export const getpackageList = (args) => ({
  type: "API",
  payload: {
    url: API_PACKAGEMANAGE_LIST + `?${args}`,
    method: "GET",
    hideLoader: false,
    success: (data) => ({
      type: PACKAGE_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: PACKAGE_LIST_F,
      payload: [],
    }),
  },
});

export const addPackageAction = (data) => ({
  type: "API",
  payload: {
    url: API_ADD_PACKAGEMANAGE,
    method: "POST",
    hideLoader: false,
    data: data
  },
});

export const getpackageDetailAction = (id) => ({
  type: "API",
  payload: {
    url: API_GET_PACKAGEMANAGE_DEATILES + id,
    method: "GET",
    hideLoader: false,
    success: (data) => ({
      type: PACKAGE_DETAILE_S,
      payload: data,
    }),
    error: (data) => ({
      type: PACKAGE_DETAILE_F,
      payload: [],
    }),
  },
});

export const editPackageAction = (data) => ({
  type: "API",
  payload: {
    url: API_EDIT_PACKAGEMANAGE,
    method: "PUT",
    hideLoader: false,
    data: data
  },
});

export const removePackageAction = (id) => ({
  type: "API",
  payload: {
    url: API_REMOVE_PACKAGEMANAGE + id,
    method: "DELETE",
    hideLoader: false,
  },
});

// reducer
const packageSlice = createSlice({
  name: "package",
  initialState: initialState,

  extraReducers: (builder) => {

    builder.addCase(PACKAGE_LIST_S, (state, action) => {
      state.packageList = action.payload;
    });
    builder.addCase(PACKAGE_LIST_F, (state, action) => {
      state.packageList = [];
    });

    builder.addCase(PACKAGE_DETAILE_S, (state, action) => {
      state.packageDetaile = action.payload;
    });
    builder.addCase(PACKAGE_DETAILE_F, (state, action) => {
      state.packageDetaile = {};
    });
  },
});


export default packageSlice.reducer;