import { Button, Form, Input, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import BreadCrumbs from "../../components/common/Breadcrumbs";
import Card from "../../components/common/Card";
import { GENDER_OPTION } from "../../constants";
import { ROUTES } from "../../constants/routesEnd";
import { getUserInfoAction } from "../../Redux/UserSlice";
import "../UserManagement/UserManagement.scss";

const list = [
  {
    name: "User Management",
    link: ROUTES.userManagement,
    isActive: true,
  },
  {
    name: "View User Management",
    link: ROUTES.editUserManagement,
    isActive: false,
  },
];

const EditUserManagement = () => {

  const dispatch = useDispatch();
  const { userId } = useParams();

  const [form] = Form.useForm();
  const userData = useSelector(state => state?.user?.userInfo);

  useEffect(() => {
    dispatch(getUserInfoAction(`/${userId}`))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  useEffect(() => {
    form.setFieldsValue({
      userName: userData?.name,
      userStatus: userData?.isActive,
      email: userData?.email,
      phoneNumber: userData?.phoneNumber || '-',
      gender: userData?.gender || '-',
      nationality: userData?.nationality || '-',
      location: userData?.location || '-',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  return (
    <>
      <div className="editUserManagement">
        <BreadCrumbs list={list} />
        <Card>
          <Form layout="vertical"
            form={form}
            disabled
          >
            <div className="row">
              <div className="col-md-12">
                <h3 className="pageLabel mar-bottom-18">
                  View User Management
                </h3>
              </div>
              <div className="col-xl-6">
                <Form.Item
                  className="form-group"
                  label="Full Name"
                  labelWrap={true}
                  name="userName"
                >
                  <Input />
                </Form.Item>
              </div>

              <div className="col-xl-6">
                <Form.Item
                  className="form-group"
                  label="Email Address"
                  labelWrap={true}
                  name="email"
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-xl-6">
                <Form.Item
                  className="form-group"
                  label="Phone Number"
                  labelWrap={true}
                  name="phoneNumber"
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-xl-6">

                <Form.Item
                  className="form-group"
                  label="Gender"
                  labelWrap={true}
                  name="gender"
                >
                  <Select
                    options={GENDER_OPTION}
                  />
                </Form.Item>
              </div>
              <div className="col-xl-6">
                <Form.Item
                  className="form-group"
                  label="Natioality"
                  labelWrap={true}
                  name="nationality"
                >
                  <Input />

                </Form.Item>
              </div>
              <div className="col-xl-6">
                <Form.Item
                  className="form-group"
                  label="Location"
                  labelWrap={true}
                  name="location"
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-xl-6">
                <Form.Item
                  className="form-group"
                  label="Status"
                  labelWrap={true}
                  name="userStatus"
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-xl-12 d-flex justify-content-end mar-top-8">
                <Button className="mar-right-8" disabled={false}>
                  <Link to={ROUTES.userManagement}>Cancel</Link>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default EditUserManagement;
