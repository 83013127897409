
export const ROUTES = {

    //OpenRoutes
    default: `/`,

    //Auth
    login: '/login',

    //change password
    changePassword: '/myprofile/changepassword',

    //userManagement
    userManagement: '/userManagement',
    editUserManagement: '/userManagement/view',

    //carManagement
    newCar: '/NewCar',
    newCarSingleCarDetails: '/newCar/singleCarDetails/view',
    newCarSingleCarDetailsEdit: '/newCar/singleCarDetails/edit',
    newCarMultipleCarDetails: '/newCar/multipleCarDetails/view',
    multipleCarDetails: '/multipleCarDetails/view',
    newCarMultipleCarListDetails: '/newCar/multipleCarDetail/view',

    newCarMultipleCarDetailsEdit: '/newCar/multipleCarDetails/edit',

    usedCar: '/UsedCar',
    usedCarSingleCarDetails: '/usedCar/singleCarDetails/view',
    usedCarSingleCarDetailsEdit: '/usedCar/singleCarDetails/edit',
    usedCarMultipleCarDetails: '/usedCar/multipleCarDetails/view',
    usedCarMultipleCarDetailsEdit: '/usedCar/multipleCarDetails/edit',
    usedCarMultipleCarListDetails: '/usedCar/multipleCarDetail/view',

    // auto parts management
    autoPartsManagement: "/AutoPartManagement",
    autoPartsManagementDetail: "/AutoPartManagement/view",
    autoPartsManagementDetailEdit: "/AutoPartManagement/edit",

    //package management
    addPackage: '/package/addPackage',
    editPackage: '/package/editPackage',
    viewPackage: '/package/viewPackage',
    packagenewCar: '/package/newCar',
    packagenewCarSingleCarDetails: '/package/newCar/singleCarDetails/view',
    packagenewCarSingleCarDetailsEdit: '/package/newCar/singleCarDetails/edit',
    packagenewCarMultipleCarDetails: '/package/newCar/multipleCarDetails/view',
    packagemultipleCarDetails: '/package/multipleCarDetails/view',
    packagenewCarMultipleCarDetailsEdit: '/package/newCar/multipleCarDetails/edit',

    packageusedCar: '/package/usedCar',
    packageusedCarSingleCarDetails: '/package/usedCar/singleCarDetails/view',
    packageusedCarSingleCarDetailsEdit: '/package/usedCar/singleCarDetails/edit',
    packageAutoPartsManagement: '/package/autoPartManagement',

    // Category Management
    CategoryManagement: '/categoryManagement',
    viewCategory: '/categoryManagement/viewCategory',

    //Contact Listing
    contact: '/contactManagement',

    //Post Transaction
    postTransaction: '/postTransaction',

    // vat Management
    vatManagement: `/vatManagement`
}