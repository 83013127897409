import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { PRODUCTS } from "../../../constants";
import { ROUTES } from "../../../constants/routesEnd";
import { loaderChange } from "../../../Redux/AuthSlice";
import { getpackageList } from "../../../Redux/PackageSlice";
import AutoPartsTable from "./AutoPartsTable";

const list = [
  {
    name: "Package Management",
    link: ROUTES.packageAutoPartsManagement,
    isActive: true,
  },
  {
    name: "Auto Parts",
    link: "/",
    isActive: false,
  },
];

const PacakageAutoPartsManagement = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [args, setArgs] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  useEffect(() => {

    dispatch(loaderChange(true))
    const getData = setTimeout(() => {
      packageautoPartList()
      dispatch(loaderChange(false))
    }, 500)

    return () => clearTimeout(getData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args])

  const packageautoPartList = () => {
    dispatch(
      getpackageList(
        `PageNumber=${args.pageNumber}&PageSize=${args.pageSize}&PostType=${PRODUCTS.accessories.value}&CarSubType=${PRODUCTS.accessories.value}`
      )
    )
  };

  const handleRedirect = () => {
    navigate(ROUTES.addPackage)
  }

  return (
    <>
      <div className="packageMain">
        <div className="packegeTitle">
          <BreadCrumbs list={list} />
          <Button onClick={handleRedirect}>Add Package</Button>
        </div>
        <AutoPartsTable args={args} setArgs={setArgs} packageautoPartList={packageautoPartList} />
      </div>
    </>
  );
};

export default PacakageAutoPartsManagement;
