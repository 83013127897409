import React, { lazy, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NewCar from "./CarManagement/NewCar";
import UsedCar from "./CarManagement/UsedCar";
import EditUserManagement from "./UserManagement/edit";
import AuthGuard from "../components/auth";
import PublicRoute from "../components/auth/public";
import NewSinglePost from "./CarManagement/NewCar/singleCar/Post";
import UsedSinglePost from "./CarManagement/UsedCar/singleCar/Post";
import NewMultiplePost from "./CarManagement/NewCar/multipleCar/Post";
import UsedMultiplePost from "./CarManagement/UsedCar/mutipleCar/Post";
import NewCarPacakagemanagement from "./PackageManagement/NewCar";
import UsedCarpackageManagement from "./PackageManagement/UsedCar";
import AutoPartPackagement from "./PackageManagement/AutoParts/index";
import CategoryManagement from "./CategoryManagement/Category";

import { ROUTES } from "../constants/routesEnd";
import Multicardetails from "./CarManagement/UsedCar/mutipleCar/ViewMulticardetails";


const LoginIn = lazy(() => import("./LoginIn"));
const Layout = lazy(() => import("../components/layout"));
const ChangePassword = lazy(() => import("./MyProfile/ChangePassword"));
const UserManagement = lazy(() => import("./UserManagement"));
const AutoPartsManagement = lazy(() => import("./AutoPartManagement"));
const AutoPartsManagementDetail = lazy(() => import("./AutoPartManagement/Details"));
const AddPackage = lazy(() => import("./PackageManagement/PackageCreation/AddPackage"));
const ViewPackage = lazy(() => import("./PackageManagement/PackageCreation/ViewPackage"));
const ViewCategory = lazy(() => import("./CategoryManagement/Category/ViewCategory"));
const ContactManagement = lazy(() => import("./ContactManagement"));
const PostTransaction = lazy(() => import("./PostTransaction"));
const VatManagement = lazy(() => import("./VatManagement"));
const NewMultiPostDeatils = lazy(() => import("./CarManagement/NewCar/multipleCar/Post/PostDetail"));
const UsedMultiPostDeatils = lazy(() => import("./CarManagement/UsedCar/mutipleCar/Post/PostDetail"));

const Routing = () => {
  const [container, setContainer] = useState(null);

  return (
    <Routes>
      <Route path={ROUTES.login} element={<PublicRoute><LoginIn /></PublicRoute>} />
      <Route
        path="/"
        element={<AuthGuard><Layout setContainer={setContainer} container={container} /></AuthGuard>}
      >
        <Route path={ROUTES.default} element={<Navigate replace to={ROUTES.userManagement} />} />

        <Route path={ROUTES.changePassword} element={<ChangePassword />} />

        {/* user management */}
        <Route path={ROUTES.userManagement} element={<UserManagement />} />
        <Route path={ROUTES.editUserManagement + `/:userId`} element={<EditUserManagement />} />

        {/* car management */}
        {/* new car */}
        <Route path={ROUTES.newCar} element={<NewCar />} />
        <Route path={ROUTES.newCarSingleCarDetails + `/:postId`} element={<NewSinglePost />}></Route>
        <Route path={ROUTES.newCarSingleCarDetailsEdit + `/:postId`} element={<NewSinglePost />}></Route>
        <Route path={ROUTES.newCarMultipleCarDetails + `/:postId`} element={<NewMultiplePost />}></Route>
        <Route path={ROUTES.newCarMultipleCarDetailsEdit + `/:postId`} element={<NewMultiplePost />}></Route>
        <Route path={ROUTES.newCarMultipleCarListDetails + `/:postId`} element={<NewMultiPostDeatils />}></Route>

        {/* used car */}
        <Route path={ROUTES.usedCar} element={<UsedCar />} />

        <Route path={ROUTES.usedCarSingleCarDetails + `/:postId`} element={<UsedSinglePost />} />
        <Route path={ROUTES.usedCarSingleCarDetailsEdit + `/:postId`} element={<UsedSinglePost />} />
        <Route path={ROUTES.usedCarMultipleCarDetails + `/:postId`} element={<UsedMultiplePost />}></Route>
        <Route path={ROUTES.usedCarMultipleCarDetailsEdit + `/:postId`} element={<UsedMultiplePost />}></Route>
        <Route path={ROUTES.multipleCarDetails + `/:postId`} element={<Multicardetails />}></Route>
        <Route path={ROUTES.usedCarMultipleCarListDetails + `/:postId`} element={<UsedMultiPostDeatils />}></Route>

        {/* auto parts management */}
        <Route path={ROUTES.autoPartsManagement} element={<AutoPartsManagement />} />
        <Route path={ROUTES.autoPartsManagementDetail + `/:postId`} element={<AutoPartsManagementDetail />} />
        <Route path={ROUTES.autoPartsManagementDetailEdit + `/:postId`} element={<AutoPartsManagementDetail />} />

        {/* Package new car */}
        <Route path={ROUTES.packagenewCar} element={<NewCarPacakagemanagement />} />

        {/* used car */}
        <Route path={ROUTES.addPackage} element={<AddPackage />} />
        <Route path={ROUTES.editPackage + `/:packageId`} element={<ViewPackage />} />
        <Route path={ROUTES.viewPackage + `/:packageId`} element={<ViewPackage />} />
        <Route path={ROUTES.packageusedCar} element={<UsedCarpackageManagement />} />

        {/* category management */}
        <Route path={ROUTES.CategoryManagement} element={<CategoryManagement />} />

        {/* package management */}
        <Route path={ROUTES.packageAutoPartsManagement} element={<AutoPartPackagement />} />
        <Route path={ROUTES.viewCategory + `/:categoryId`} element={<ViewCategory />} />

        {/*Post Transaction*/}
        <Route path={ROUTES.postTransaction} element={<PostTransaction />} />

        {/*Vat Management*/}
        <Route path={ROUTES.vatManagement} element={<VatManagement />} />

        {/* Contact Mangement */}
        <Route path={ROUTES.contact} element={<ContactManagement />} />

        {/*Post Transaction*/}
        <Route path={ROUTES.postTransaction} element={< PostTransaction />} />

      </Route>
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default Routing;
