import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Tabs } from "antd";

import NewSingleCar from "./singleCar";
import NewMutipleCar from "./multipleCar";
import BreadCrumbs from "../../../components/common/Breadcrumbs";
import { LS_NEW_CAR_PACKAGE_TAB, PRODUCTS, PRODUCTS_TYPE } from "../../../constants";
import { ROUTES } from "../../../constants/routesEnd";
import { getpackageList } from "../../../Redux/PackageSlice";
import { useNavigate } from "react-router-dom";
import { loaderChange } from "../../../Redux/AuthSlice";

const NewCarPacakagemanagement = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("1");
  const [args, setArgs] = useState({
    pageNumber: 1,
    pageSize: 10,
    searchString: '',
    OrderBy: '',
    SortField: ''
  });

  const getCurrentTab = localStorage.getItem(LS_NEW_CAR_PACKAGE_TAB.toString())


  useEffect(() => {

    dispatch(loaderChange(true))
    const getData = setTimeout(() => {
      packageNewList()
      dispatch(loaderChange(false))
    }, 500)

    return () => clearTimeout(getData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args, currentTab])

  useEffect(() => {
    setCurrentTab(getCurrentTab);
  }, [getCurrentTab])

  const packageNewList = () => {
    if (currentTab === "1" && getCurrentTab === "1") {
      dispatch(
        getpackageList(
          `PageNumber=${args.pageNumber}&PageSize=${args.pageSize}&PostType=${PRODUCTS.new.value}&CarSubType=${PRODUCTS_TYPE.single.value}`
        )
      )
    }
    else {
      dispatch(
        getpackageList(
          `PageNumber=${args.pageNumber}&PageSize=${args.pageSize}&PostType=${PRODUCTS.new.value}&CarSubType=${PRODUCTS_TYPE.multiple.value}`
        )
      )
    }
  };

  const list = [
    {
      name: "Package Management",
      link: ROUTES.packagenewCar,
      isActive: true,
    },
    {
      name: "New Car",
      link: "/",
      isActive: false,
    },
  ];

  const tabNames = [
    {
      label: `Single car post`,
      key: "1",
      children: <NewSingleCar args={args} setArgs={setArgs} packageNewList={packageNewList} />,
    },
    {
      label: `Multiple car post`,
      key: "2",
      children: <NewMutipleCar args={args} setArgs={setArgs} packageNewList={packageNewList} />,
    },
  ];

  const onChange = (key) => {
    localStorage.setItem(LS_NEW_CAR_PACKAGE_TAB, key);
    setCurrentTab(key);
  };

  const handleRedirect = () => {
    navigate(ROUTES.addPackage)
  }

  return (
    <><div className="packageMain">
      <div className="packegeTitle">

        <BreadCrumbs list={list} />
        <Button onClick={handleRedirect}>Add Package</Button>
      </div>
      <Tabs
        onChange={onChange}
        size="middle"
        defaultActiveKey={currentTab}
        activeKey={getCurrentTab}
        items={tabNames}
      />
    </div>
    </>
  );
};

export default NewCarPacakagemanagement;
