
// TYPES FOR REDUCER
export const LOGIN_S = `LOGIN_S`;
export const LOGIN_F = `LOGIN_F`;

export const FORGOT_PASSWORD_S = `FORGOT_PASSWORD_S`;
export const FORGOT_PASSWORD_F = `FORGOT_PASSWORD_F`;

export const RESET_PASSWORD_S = `RESET_PASSWORD_S`;
export const RESET_PASSWORD_F = `RESET_PASSWORD_F`;

export const CHANGE_PASSWORD_S = `CHANGE_PASSWORD_S`;
export const CHANGE_PASSWORD_F = `CHANGE_PASSWORD_F`;

// user management

export const USER_LIST_S = `USER_LIST_S`;
export const USER_LIST_F = `USER_LIST_F`;

export const USER_INFO_S = `USER_INFO_S`;
export const USER_INFO_F = `USER_INFO_F`;

// car management

export const CAR_LIST_S = `CAR_LIST_S`;
export const CAR_LIST_F = `CAR_LIST_F`;

export const CAR_DETAILS_LIST_S = `CAR_DETAILS_LIST_S`;
export const CAR_DETAILS_LIST_F = `CAR_DETAILS_LIST_F`;

export const CAR_DETAIL_S = `CAR_DETAIL_S`;
export const CAR_DETAIL_F = `CAR_DETAIL_F`;


//package management

export const PACKAGE_LIST_S = `PACKAGE_LIST_S`;
export const PACKAGE_LIST_F = `PACKAGE_LIST_F`;

export const PACKAGE_DETAILE_S = `PACKAGE_DETAILE_S`;
export const PACKAGE_DETAILE_F = `PACKAGE_DETAILE_F`;

//vat management
export const VAT_LIST_S = `VAT_LIST_S`;
export const VAT_LIST_F = `VAT_LIST_F`;


//category management

export const CATEGORY_LIST_S = `CATEGORY_LIST_S`;
export const CATEGORY_LIST_F = `CATEGORY_LIST_F`;


export const CATEGORY_DETAILS_S = `CATEGORY_DETAILS_S`;
export const CATEGORY_DETAILS_F = `CATEGORY_DETAILS_F`;

//subcategory Management

export const SUB_CATEGORY_LIST_S = `SUB_CATEGORY_LIST_S`;
export const SUB_CATEGORY_LIST_F = `SUB_CATEGORY_LIST_F`;

// POST DROPDOWN 

export const BODY_CONDITION_S = `GET_BODY_CONDITION_S`;
export const BODY_CONDITION_F = `GET_BODY_CONDITION_F`;

export const BODY_TYPE_S = `GET_BODY_TYPE_S`;
export const BODY_TYPE_F = `GET_BODY_TYPE_F`;

export const CAR_COLOR_S = `GET_CAR_COLOR_S`;
export const CAR_COLOR_F = `GET_CAR_COLOR_F`;

export const CAR_MODEL_S = `GET_CAR_MODEL_S`;
export const CAR_MODEL_F = `GET_CAR_MODEL_F`;

export const CITY_S = `GET_CITY_S`;
export const CITY_F = `GET_CITY_F`;

export const HORSE_POWER_S = `GET_HORSE_POWER_S`;
export const HORSE_POWER_F = `GET_HORSE_POWER_F`;

export const INTERIOR_COLOR_S = `GET_INTERIOR_COLOR_S`;
export const INTERIOR_COLOR_F = `GET_INTERIOR_COLOR_F`;

export const MAKE_S = `GET_MAKE_S`;
export const MAKE_F = `GET_MAKE_F`;

export const MECHENICAL_CONDITION_S = `GET_MECHENICAL_CONDITION_S`;
export const MECHENICAL_CONDITION_F = `GET_MECHENICAL_CONDITION_F`;

export const USAGE_S = `GET_USAGE_S`;
export const USAGE_F = `GET_USAGE_F`;

//contact management 

export const CONTACT_LIST_S = `CONTACT_LIST_S`;
export const CONTACT_LIST_F = `CONTACT_LIST_F`;
//post Transcation List

export const POST_TRANSCATION_LIST_S = `POST_TRANSCATION_LIST_S`;
export const POST_TRANSCATION_LIST_F = `POST_TRANSCATION_LIST_F`;
