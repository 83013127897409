import React from 'react';
import { Input } from 'antd';
import './Upload.scss';

const UploadFile = ({ setFileupload, uploadText, uploadLabel, imageUrl, setImageUrl, uplaodWidth, uplaodHeight }) => {

    const handleChange = (e) => {
        setFileupload(e.target.files[0]);

        const reader = new FileReader();
        reader.addEventListener("load", () => {
            setImageUrl(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);
    }

    return (
        <>
            {uploadLabel ? <label className='upload-label'>{uploadLabel}</label> : ''}

            {imageUrl ?
                <div className='uplaoded-ui' style={{ width: uplaodWidth, height: uplaodHeight }}>
                    <img src={imageUrl} alt="" />
                    <Input accept=".png, .jpg, .jpeg" type='file' name="file" onChange={(e) => handleChange(e)}></Input>
                </div>
                :
                <div className='uplaod-ui' style={{ width: uplaodWidth, height: uplaodHeight }}>
                    <span className='uplaod-span'><img src={'/images/uplaod-icon.svg'} alt="" /><br />{uploadText}</span>
                    <Input accept=".png, .jpg, .jpeg" type='file' name="file" onChange={(e) => handleChange(e)}></Input>
                </div>
            }
        </>
    );
}

export default UploadFile;