export const multiplecarlistcolumns = [
        {
            title: "Post Id",
            dataIndex: "postDetailsId",
            key: "postDetailsId",
            render: (row) => (
                <div>
                    <div>{row}</div>
                </div>
            ),
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            render: (row) => (
                <div>
                    <div>{row ? row : "-"}</div>
                </div>
            ),
        },
        {
            title: "Package Price",
            dataIndex: "price",
            key: "price",
            render: (row) => (
                <div>
                    <div>{row ? row : "-"}</div>
                </div>
            ),
        },
    ];