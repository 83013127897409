export const fuelOption = [
	{ value: 1, label: 'Gasoline' },
	{ value: 2, label: 'Diesel' },
	{ value: 3, label: 'Hybrid' },
	{ value: 4, label: 'Electric' },
	{ value: 5, label: 'Petrol' },
];

export const steeringOption = [
	{ value: 1, label: 'Left' },
	{ value: 2, label: 'Right' },
];

export const seatOption = [
	{ value: 2, label: '2' },
	{ value: 4, label: '4' },
	{ value: 5, label: '5' },
	{ value: 7, label: '7' },
	{ value: 9, label: '9' },
];
export const doorOption = [
	{ value: 1, label: '1' },
	{ value: 2, label: '2' },
	{ value: 3, label: '3' },
	{ value: 4, label: '4' },
	{ value: 5, label: '5' },
	{ value: 6, label: '6' },
	{ value: 7, label: '7' },
];

export const cylinderOption = [
	{ value: 2, label: '2' },
	{ value: 3, label: '3' },
	{ value: 4, label: '4' },
	{ value: 5, label: '5' },
	{ value: 6, label: '6' },
	{ value: 7, label: '7' },
	{ value: 8, label: '8' },
	{ value: 9, label: '9' },
	{ value: 10, label: '10' },
	{ value: 11, label: '11' },
	{ value: 12, label: '12' },
];

export const transmissionOption = [
	{ value: 1, label: 'Manual' },
	{ value: 2, label: 'Automatic' },
];

export const WARRANTY_OPTIONS = [
	{ value: 1, label: 'Yes' },
	{ value: 2, label: 'No' },
	{ value: 3, label: 'Applied' },
	{ value: 4, label: 'Does Not Applied' },
];
