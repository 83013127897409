// ENV CONSTANTS
export const { REACT_APP_API_BASE: API_BASE } = process.env;
export const { REACT_APP_NAME: APP_NAME } = process.env;
export const { REACT_APP_GOOGLE_PLACE_API_KEY: GOOGLE_PLACE_KEY } = process.env;

// LOCALSTORAGE KEYNAME
export const LS_USER = `user${APP_NAME}`
export const LS_AUTHTOKEN = `authToken${APP_NAME}`
export const LS_NEW_CAR_TAB = `newCarTab${APP_NAME}`
export const LS_USED_CAR_TAB = `usedCarTab${APP_NAME}`
export const LS_NEW_CAR_PACKAGE_TAB = `newCarPackageTab${APP_NAME}`
export const LS_USED_CAR_PACKAGE_TAB = `usedCarPackageTab${APP_NAME}`

// CONSTANTS
export const PRODUCTS = {
    used: {
        name: 'used',
        value: 1,
    },
    new: {
        name: 'new',
        value: 2
    },
    accessories: {
        name: 'accessories',
        value: 3,
    },
}

export const PRODUCTS_TYPE = {
    single: {
        value: 1
    },
    multiple: {
        value: 2
    }
}



export const AD_TYPE = [
    { value: 1, label: 'Used Car' },
    { value: 2, label: 'New car' },
    { value: 3, label: 'Auto Parts' },

];

export const CAR_NUMBER_OPTION = [
    {
        value: 1,
        label: "1",
    },
    {
        value: 2,
        label: "2",
    },
    {
        value: 3,
        label: "3",
    },
    {
        value: 4,
        label: "4",
    },
    {
        value: 5,
        label: "5",
    },
    {
        value: 6,
        label: "6",
    },
];

export const statusFilter = [
    {
        label: "Active",
        value: "Active",
        disabled: false,
    },
    {
        label: "In Active",
        value: "inActive",
        disabled: false,
    },
    {
        label: "Sold",
        value: "Sold",
        disabled: false,
    },
];


export const packageTypeFilter = [
    {
        label: "Car car car",
        value: "Car",
        disabled: false,
    },
    {
        label: "Car car car",
        value: "KTM",
        disabled: false,
    },
    {
        label: "1",
        value: "1",
        disabled: false,
    },
    {
        label: "2",
        value: "2",
        disabled: false,
    },
    {
        label: "3",
        value: "3",
        disabled: false,
    },
];

export const POST_STATUS = [
    {
        label: "Active",
        value: 1,
        disabled: false,
    },
    {
        label: "In Active",
        value: 2,
        disabled: false,
    },
];

export const GENDER_OPTION = [
    {
        value: 1,
        label: "Male",
    },
    {
        value: 2,
        label: "Female",
    },
    {
        value: 3,
        label: "Other",
    },
];