import React from "react";
import NewSingleCarTable from "./NewSingleCarTable";

const NewSingleCar = ({ args, setArgs, packageNewList }) => {

  return (
    <>
      <div className="multipleCarPost shadow-paper auto-height ">
        <NewSingleCarTable args={args} setArgs={setArgs} packageNewList={packageNewList} />
      </div>
    </>
  );
};

export default NewSingleCar;
