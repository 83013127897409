import { createSlice } from "@reduxjs/toolkit";
import { API_POST_TRASACTION_LIST } from "../constants/api";
import { POST_TRANSCATION_LIST_F, POST_TRANSCATION_LIST_S } from "../constants/type";

const initialState = {
  postTranscationList: [],
};

export const getpostTranscationList = (data, args) => ({
  type: "API",
  payload: {
    url: API_POST_TRASACTION_LIST + `?${args}`,
    method: "POST",
    hideLoader: false,
    data: data,
    success: (data) => ({
      type: POST_TRANSCATION_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: POST_TRANSCATION_LIST_F,
      payload: [],
    }),
  },
});


// reducer
const postTranscationSlice = createSlice({
  name: "PostTranscation",
  initialState: initialState,

  extraReducers: (builder) => {

    builder.addCase(POST_TRANSCATION_LIST_S, (state, action) => {
      state.postTranscationList = action.payload;
    });
    builder.addCase(POST_TRANSCATION_LIST_F, (state, action) => {
      state.postTranscationList = [];
    });
  },
});


export default postTranscationSlice.reducer;