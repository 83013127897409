import { createSlice } from "@reduxjs/toolkit";
import { API_ADD_CATEGORY, API_ADD_SUB_CATEGORY, API_CATEGORY_LIST, API_CATEGORY_VIEW, API_EDIT_CATEGORY, API_EDIT_SUB_CATEGORY, API_REMOVE_IMAGE, API_SUB_CATEGORY_LIST, API_UPLOAD_IMAGE } from "../constants/api";
import { CATEGORY_DETAILS_F, CATEGORY_DETAILS_S, CATEGORY_LIST_F, CATEGORY_LIST_S, SUB_CATEGORY_LIST_F, SUB_CATEGORY_LIST_S } from "../constants/type";

const initialState = {
  categoryList: [],
  subCategoryList: [],
  categoryDetail: {},
};

export const getCategoryList = (args) => ({
  type: "API",
  payload: {
    url: API_CATEGORY_LIST + `?${args}`,
    method: "GET",
    hideLoader: false,
    // data: data,
    success: (data) => ({
      type: CATEGORY_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: CATEGORY_LIST_F,
      payload: [],
    }),
  },
});

export const getSubCategoryList = (args) => ({
  type: "API",
  payload: {
    url: API_SUB_CATEGORY_LIST + `?${args}`,
    method: "GET",
    hideLoader: false,
    // data: data,
    success: (data) => ({
      type: SUB_CATEGORY_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: SUB_CATEGORY_LIST_F,
      payload: [],
    }),
  },
});

export const viewCategoryAction = (data) => ({
  type: "API",
  payload: {
    url: API_CATEGORY_VIEW + data,
    method: "GET",
    hideLoader: false,
    success: (data) => ({
      type: CATEGORY_DETAILS_S,
      payload: data,
    }),
    error: (data) => ({
      type: CATEGORY_DETAILS_F,
      payload: [],
    }),
  },
});

export const addCategory = (data) => ({
  type: "API",
  payload: {
    url: API_ADD_CATEGORY,
    method: "POST",
    hideLoader: false,
    data: data,
  },
});

export const addSubCategory = (data) => ({
  type: "API",
  payload: {
    url: API_ADD_SUB_CATEGORY,
    method: "POST",
    hideLoader: false,
    data: data,
  },
});

export const editCategoryAction = (data) => ({
  type: "API",
  payload: {
    url: API_EDIT_CATEGORY,
    method: "PUT",
    hideLoader: false,
    data: data,
  },
});

export const editSubCategoryAction = (data) => ({
  type: "API",
  payload: {
    url: API_EDIT_SUB_CATEGORY,
    method: "PUT",
    hideLoader: false,
    data: data,
  },
});

export const uploadImageAction = (data) => ({
  type: "API",
  payload: {
    url: API_UPLOAD_IMAGE,
    method: "POST",
    hideLoader: false,
    data: data,
  },
});

export const removeImageAction = (data) => ({
  type: "API",
  payload: {
    url: API_REMOVE_IMAGE + data,
    method: "DELETE",
    hideLoader: false,
  },
});




// reducer
const categorySlice = createSlice({
  name: "category",
  initialState: initialState,

  extraReducers: (builder) => {

    builder.addCase(CATEGORY_LIST_S, (state, action) => {
      state.categoryList = action.payload;
    });
    builder.addCase(CATEGORY_LIST_F, (state, action) => {
      state.categoryList = [];
    });
    builder.addCase(CATEGORY_DETAILS_S, (state, action) => {
      state.categoryDetail = action.payload;
    });
    builder.addCase(CATEGORY_DETAILS_F, (state, action) => {
      state.categoryDetail = {};
    });
    builder.addCase(SUB_CATEGORY_LIST_S, (state, action) => {
      state.subCategoryList = action.payload;
    });
    builder.addCase(SUB_CATEGORY_LIST_F, (state, action) => {
      state.subCategoryList = [];
    });

  },
});


export default categorySlice.reducer;