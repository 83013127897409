import { Modal } from "antd";
import React from "react";
import './modal.scss';

const CustomModal = (props) => {
  const { title, open, onOk, onCancel, footer, modalCLass } = props;

  return (
    <>
      <Modal title={title} open={open} onOk={onOk} onCancel={onCancel} centered footer={footer} className={modalCLass}>
        {props.children}
      </Modal>
    </>
  );
};

export default CustomModal;
