import { createSlice } from "@reduxjs/toolkit";
import { API_USER_DELETE, API_USER_EDIT, API_USER_INFO, API_USER_LIST } from "../constants/api";
import { USER_INFO_F, USER_INFO_S, USER_LIST_F, USER_LIST_S } from "../constants/type";

const initialState = {
  userList: [],
  userInfo: {},
};

export const getUserList = (data) => ({
  type: "API",
  payload: {
    url: API_USER_LIST + data,
    method: "GET",
    hideLoader: false,
    success: (data) => ({
      type: USER_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: USER_LIST_F,
      payload: [],
    }),
  },
});

export const deleteUserAction = (data) => ({
  type: "API",
  payload: {
    url: API_USER_DELETE + data,
    method: "DELETE",
    hideLoader: false,
  },
});

export const editUserAction = (data) => ({
  type: "API",
  payload: {
    url: API_USER_EDIT,
    method: "PUT",
    hideLoader: false,
    data: data
  },
});

export const getUserInfoAction = (data) => ({
  type: "API",
  payload: {
    url: API_USER_INFO + data,
    method: "GET",
    hideLoader: false,
    success: (data) => ({
      type: USER_INFO_S,
      payload: data,
    }),
    error: (data) => ({
      type: USER_INFO_F,
      payload: [],
    }),
  },
});


// Reducer
const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(USER_LIST_S, (state, action) => {
      state.userList = action.payload;
    });
    builder.addCase(USER_LIST_F, (state, action) => {
      state.userList = [];
    });
    builder.addCase(USER_INFO_S, (state, action) => {
      state.userInfo = action.payload;
    });
    builder.addCase(USER_INFO_F, (state, action) => {
      state.userInfo = {};
    });
  },
});

export default userSlice.reducer;
