import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../../../../components/common/Breadcrumbs";
import { getCarDetailListAction } from "../../../../../Redux/CarSlice";
import { ROUTES } from "../../../../../constants/routesEnd";
import { LS_USED_CAR_TAB } from "../../../../../constants";
import UsedPostTable from "./PostList";

const list = [
  {
    name: "Used Car",
    link: ROUTES.usedCar,
    isActive: true,
  },
  {
    name: "Multiple Car Details",
    link: "/",
    isActive: false,
  },
];

const UsedMultiplePost = () => {

  const dispatch = useDispatch();
  const { postId } = useParams();

  const carNewList = () => {
    dispatch(getCarDetailListAction(`/${postId}`));
  };

  useEffect(() => {
    localStorage.setItem(LS_USED_CAR_TAB, "2")
  }, [])

  useEffect(() => {
    dispatch(getCarDetailListAction(`/${postId}`))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId])


  return (
    <>
      <div className="postDetails">
        <BreadCrumbs list={list} />
        <UsedPostTable autoPartList={carNewList} />
      </div>
    </>
  );
};

export default UsedMultiplePost;
