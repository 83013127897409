import { configureStore } from '@reduxjs/toolkit'
import reduxApiMiddleware from './Middleware'
import thunk from 'redux-thunk'
import AuthSlice from './AuthSlice'
import UserSlice from './UserSlice'
import CarSlice from './CarSlice'
import metaSlice from './MetaSlice'
import packageSlice from './PackageSlice'
import CategorySlice from './CategorySlice'
import ContactSlice from './ContactSlice'
import postTranscationSlice from './PostTransactionSlice'
import vatPercentageSlice from './VatManagement'

export const store = configureStore({
    reducer: {
        auth: AuthSlice,
        user: UserSlice,
        car: CarSlice,
        package: packageSlice,
        metaSlice: metaSlice,
        category: CategorySlice,
        postTranscation: postTranscationSlice,
        contact: ContactSlice,
        vat: vatPercentageSlice
    },
    middleware: [thunk, reduxApiMiddleware]
}) 