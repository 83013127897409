import React from "react";
import { Col, Input, Row } from "antd";

import UsedMutipleCarTable from "./UsedMutipleCarTable";
import SearchInput from "../../../../components/Searchbox";
const { Search } = Input;

const UsedMutipleCar = ({ args, setArgs, carUsedList }) => {
  const onSearch = (value) => setArgs({ ...args, searchString: value });
  const handleChange = (e) =>
    setArgs({ ...args, searchString: e.target.value });

  return (
    <>
      <div className="multipleCarPost shadow-paper auto-height ">
        <SearchInput onSearch={onSearch} handleChange={handleChange} />
        <UsedMutipleCarTable
          args={args}
          setArgs={setArgs}
          carUsedList={carUsedList}
        />
      </div>
    </>
  );
};

export default UsedMutipleCar;
